import { controllers } from "chart.js";

const totalLeaksFoundCalculation = (arr) => {
  return arr.reduce((count, currentVal) => {
    if (currentVal.verification_result === "Leak") {
      return count + 1;
    }
    return count;
  }, 0);
};

const pipeLengthInvestigatedCalculation = (arr) => {
  return arr
    ? arr.reduce((count, currentVal) => {
        if (
          currentVal.verification_result &&
          currentVal.verification_result !== "Unverifiable"
        ) {
          return count + currentVal.pipe_lengt;
        }
        return count;
      }, 0) / 1000
    : 0;
};

const pipeLengthTotalCalculation = (arr) => {
  return arr
    ? arr.reduce((count, currentVal) => {
        if (
          currentVal.verification_result &&
          currentVal.verification_result === "Unverifiable"
        ) {
          return count;
        }
        return count + currentVal.pipe_lengt;
      }, 0) / 1000
    : 0;
};

const numberOfTotalPoiCalculation = (arr) => {
  return arr
    ? arr.reduce((count, currentVal) => {
        if (
          currentVal.verification_result &&
          currentVal.verification_result === "Unverifiable"
        ) {
          return count;
        }
        return count + 1;
      }, 0)
    : 0;
};

const numberOfLeaksVisibleNoCalculation = (arr) => {
  return arr
    ? arr.reduce((count, currentVal) => {
        if (
          currentVal.verification_result &&
          currentVal.verification_result === "Leak" &&
          currentVal.visible === "No"
        ) {
          return count + 1;
        }
        return count;
      }, 0)
    : 0;
};

const sumHours = (arr) => {
  const arrayDate = [];
  arr.map((val) => {
    val.owners.map((valOwners) => {
      arrayDate.push(valOwners.hours.length + 1);
    });
  });
  return arrayDate.reduce((a, b) => a + b, 0);
};

const combineHoursForOwners = (arr) => {
  const owners = [];
  arr.values.map((val) => {
    const findOwner = owners.find((own) => {
      return own.owner === val.__owner;
    });
    if (!findOwner) {
      owners.push({
        owner: val.__owner,
        hours: [val.hour],
      });
    } else {
      if (!findOwner.hours.includes(val.hour)) {
        findOwner.hours.push(val.hour);
      }
    }
  });

  return { date: arr.date, owners: owners };
};

const groupDates = (arr) => {
  const groupedDates = [];
  arr.map((value) => {
    if (
      value.verification_result &&
      value.verification_result !== "Unverifiable" &&
      !(value.verification_result === "Leak" && value.leak_type === "WO")
    ) {
      const dateTemp = value.timestamp_corrected.split(" ");
      const dateNew = {
        date: dateTemp[0],
        hour: dateTemp[1].split(":")[0],
        __owner: value.__owner,
        verification_result: value.verification_result,
      };
      const findDate = groupedDates.find((val) => val.date === dateNew.date);
      if (!findDate) {
        groupedDates.push({
          date: dateNew.date,
          values: [dateNew],
        });
      } else {
        findDate.values.push(dateNew);
      }
    }
  });

  return groupedDates;
};

const crewDaysCalculation = (arr) => {
  const groupedDates = groupDates(arr);
  const combiendOwnersHours = groupedDates.map((val) =>
    combineHoursForOwners(val)
  );
  // total works hours divided by 6.5, 6.5 is total works hours per day
  return sumHours(combiendOwnersHours) / 6.5;
};

const totalLeaksWithoutWoCalculation = (arr) => {
  return (
    arr &&
    arr.filter(
      (val) => val.verification_result === "Leak" && val.leak_type !== "WO"
    ).length
  );
};

const waterSaveCustomerSideCalculation = (arr, customerSide, meter, pipe) => {
  const customerSideAll = arr.filter(
    (val) =>
      val.verification_result === "Leak" && val.leak_type === "Customer-Side"
  );
  const customerSideMeter = customerSideAll.filter(
    (val) => val.cust_sub_type === "Meter"
  ).length;
  const customerSidePipe = customerSideAll.filter(
    (val) => val.cust_sub_type === "Pipe"
  ).length;

  const customerSideOnly =
    customerSideAll.length - customerSideMeter - customerSidePipe;
  return (
    customerSideOnly * customerSide +
    customerSideMeter * meter +
    customerSidePipe * customerSide
  );
};

const waterSaveMainCalculation = (
  arr,
  service,
  connectionsFittings,
  fireHydrant,
  valve,
  pipe
) => {
  const main = arr.filter(
    (val) => val.verification_result === "Leak" && val.leak_type === "Main"
  );
  const mainPipe = main.filter((val) => val.main_sub_type === "Pipe").length;
  const mainValve = main.filter((val) => val.main_sub_type === "Valve").length;
  const mainFireHydrant = main.filter(
    (val) => val.main_sub_type === "Fire hydrant"
  ).length;
  const mainConnectionsFittings = main.filter(
    (val) => val.main_sub_type === "Connections-Fittings"
  ).length;
  const mainService =
    main.length -
    mainPipe -
    mainValve -
    mainFireHydrant -
    mainConnectionsFittings;

  return (
    mainService * service +
    mainConnectionsFittings * connectionsFittings +
    mainFireHydrant * fireHydrant +
    mainValve * valve +
    mainPipe * pipe
  );
};

const waterSaveOtherCalculation = (arr, service) => {
  const other = arr.filter(
    (val) => val.verification_result === "Leak" && val.leak_type === "Other"
  ).length;

  return other * service;
};

const waterSaveServiceCalculation = (
  arr,
  service,
  connectionsFittings,
  meter,
  curbstop,
  pipe
) => {
  const serviceArr = arr.filter(
    (val) => val.verification_result === "Leak" && val.leak_type === "Service"
  );
  const serviceCurbStop = serviceArr.filter(
    (val) =>
      val.verification_result === "Leak" &&
      val.service_sub_type === "Curb stop-Stop tap"
  ).length;
  const serviceMeter = serviceArr.filter(
    (val) =>
      val.verification_result === "Leak" && val.service_sub_type === "Meter"
  ).length;
  const serviceConnectionsFittings = serviceArr.filter(
    (val) =>
      val.verification_result === "Leak" &&
      val.service_sub_type === "Connections-Fittings"
  ).length;
  const servicePipe = serviceArr.filter(
    (val) =>
      val.verification_result === "Leak" && val.service_sub_type === "Pipe"
  ).length;
  return (
    serviceConnectionsFittings * connectionsFittings +
    serviceMeter * meter +
    serviceCurbStop * curbstop +
    servicePipe * service
  );
};

const waterSaveWoCalculation = (arr, service) => {
  const wo = arr.filter(
    (val) => val.verification_result === "Leak" && val.leak_type === "WO"
  ).length;
  return wo * service;
};

const waterSaveUndefinedCalculation = (arr, service) => {
  const undefined = arr.filter(
    (val) =>
      val.verification_result === "Leak" &&
      !(
        val.leak_type === "Customer-Side" ||
        val.leak_type === "WO" ||
        val.leak_type === "Service" ||
        val.leak_type === "Other" ||
        val.leak_type === "Main"
      )
  ).length;
  return undefined * service;
};

const waterSaveCalculation = (
  arr,
  service,
  meter,
  connectionsFittings,
  fireHydrant,
  valve,
  pipe,
  curbstop,
  customerSide
) => {
  const waterSaveCustomerSide = waterSaveCustomerSideCalculation(
    arr,
    customerSide,
    meter,
    pipe
  );
  const waterSaveMain = waterSaveMainCalculation(
    arr,
    service,
    connectionsFittings,
    fireHydrant,
    valve,
    pipe
  );
  const waterSaveOther = waterSaveOtherCalculation(arr, service);
  const waterSaveService = waterSaveServiceCalculation(
    arr,
    service,
    connectionsFittings,
    meter,
    curbstop,
    pipe
  );
  const waterSaveWo = waterSaveWoCalculation(arr, service);
  const waterSaveUndefined = waterSaveUndefinedCalculation(arr, service);
  return (
    waterSaveCustomerSide +
    waterSaveMain +
    waterSaveOther +
    waterSaveService +
    waterSaveWo +
    waterSaveUndefined
  );
};

const numberOfPoiTypesCalculation = (arr) => {
  let numberOfLeaksPoi = 0,
    numberOfSuspectedPoi = 0,
    numberOfQuietPoi = 0,
    numberOfUnverifiablePoi = 0;

  arr.map((val) => {
    if (val.verification_result === "Leak") {
      numberOfLeaksPoi++;
    }
    if (val.verification_result === "Suspected") {
      numberOfSuspectedPoi++;
    }
    if (val.verification_result === "Quiet") {
      numberOfQuietPoi++;
    }
    if (val.verification_result === "Unverifiable") {
      numberOfUnverifiablePoi++;
    }
  });

  return {
    numberOfLeaksPoi,
    numberOfSuspectedPoi,
    numberOfQuietPoi,
    numberOfUnverifiablePoi,
  };
};

const leakTypeTotalCalculation = (arr) => {
  let leakTypeCustomerSide = 0,
    leakTypeMain = 0,
    leakTypeService = 0,
    leakTypeWo = 0,
    leakTypeOther = 0;
  arr.map((val) => {
    if (val.verification_result === "Leak") {
      if (val.leak_type === "Customer-Side") {
        leakTypeCustomerSide++;
      } else if (val.leak_type === "Main") {
        leakTypeMain++;
      } else if (val.leak_type === "Service") {
        leakTypeService++;
      } else if (val.leak_type === "WO") {
        leakTypeWo++;
      } else {
        leakTypeOther++;
      }
    }
  });
  return {
    leakTypeCustomerSide,
    leakTypeMain,
    leakTypeService,
    leakTypeWo,
    leakTypeOther,
  };
};

const leakTypeMainCalculation = (arr) => {
  let leakTypeConnectionsFittings = 0,
    leakTypeFireHydrant = 0,
    leakTypePipe = 0,
    leakTypeValve = 0;
  arr.map((val) => {
    if (val.verification_result === "Leak" && val.leak_type === "Main") {
      if (val.main_sub_type === "Connections-Fittings") {
        leakTypeConnectionsFittings++;
      }
      if (val.main_sub_type === "Fire hydrant") {
        leakTypeFireHydrant++;
      }
      if (val.main_sub_type === "Pipe") {
        leakTypePipe++;
      }
      if (val.main_sub_type === "Valve") {
        leakTypeValve++;
      }
    }
  });

  return {
    leakTypeConnectionsFittings,
    leakTypeFireHydrant,
    leakTypePipe,
    leakTypeValve,
  };
};

const leakTypeServiceCalculation = (arr) => {
  let leakTypeConnectionsFittings = 0,
    leakTypeCurbStop = 0,
    leakTypePipe = 0,
    leakTypeMeter = 0;
  arr.map((val) => {
    if (val.verification_result === "Leak" && val.leak_type === "Service") {
      if (val.service_sub_type === "Connections-Fittings") {
        leakTypeConnectionsFittings++;
      }
      if (val.service_sub_type === "Curb stop-Stop tap") {
        leakTypeCurbStop++;
      }
      if (val.service_sub_type === "Meter") {
        leakTypeMeter++;
      }
      if (val.service_sub_type === "Pipe") {
        leakTypePipe++;
      }
    }
  });
  return {
    leakTypeConnectionsFittings,
    leakTypeCurbStop,
    leakTypePipe,
    leakTypeMeter,
  };
};

const leakTypeCustomerSideCalculation = (arr) => {
  let leakTypeUtilityLoss = 0,
    leakTypePipe = 0,
    leakTypeMeter = 0,
    leakTypeParksRecreation = 0;
  arr.map((val) => {
    if (
      val.verification_result === "Leak" &&
      val.leak_type === "Customer-Side"
    ) {
      if (val.cust_sub_type === "Meter") {
        leakTypeMeter++;
      }
      if (val.cust_sub_type === "Parks & recreation") {
        leakTypeParksRecreation++;
      }
      if (val.cust_sub_type === "Utility loss") {
        leakTypeUtilityLoss++;
      }
      if (val.cust_sub_type === "Pipe") {
        leakTypePipe++;
      }
    }
  });
  return {
    leakTypeUtilityLoss,
    leakTypePipe,
    leakTypeMeter,
    leakTypeParksRecreation,
  };
};

const leaksPerPoiPerLicenseCalculation = (arr) => {
  const crewArr = [];
  arr.map((val) => {
    if (
      !(val.verification_result === "Leak" && val.leak_type === "WO") &&
      val.verification_result !== "Unverifiable"
    ) {
      const crewData = crewArr.find((object) => object.crew === val.__owner);
      if (val.utilis_finding) {
        const utilisFinding = val.utilis_finding.trim();
        const isLeak = val.verification_result === "Leak" ? 1 : 0;
        if (!crewData) {
          crewArr.push({
            crew: val.__owner,
            leaks: isLeak,
            utilisIds: [utilisFinding],
          });
        } else {
          if (!crewData.utilisIds.includes(utilisFinding)) {
            crewData.utilisIds.push(utilisFinding);
          }
          crewData.leaks += isLeak;
        }
      }
    }
  });

  const totalPoi = crewArr.reduce((a, b) => {
    return a + b.utilisIds.length;
  }, 0);

  return crewArr.map((val) => {
    return {
      id: val.crew,
      leaks_per_poi: ((val.leaks / val.utilisIds.length) * 100).toFixed(0),
      percent_investigate: ((val.utilisIds.length / totalPoi) * 100).toFixed(0),
    };
  });
};

const resultsByDateGetDate = (arr) => {
  const days = [];
  const weeks = [];
  const months = [];

  arr.map((val) => {
    const dateDays = val.timestamp_corrected.split(" ")[0].trim();
    const dateWeek = weekNumber(dateDays);
    const dateMonth = dateDays.split("-")[0] + "-" + dateDays.split("-")[1];

    days.push({
      date: dateDays,
      verificationResult: val.verification_result,
    });
    weeks.push({
      date: dateWeek,
      verificationResult: val.verification_result,
    });
    months.push({
      date: dateMonth,
      verificationResult: val.verification_result,
    });
  });

  return { days, weeks, months };
};

// get week number from date
const weekNumber = (dateToWeek) => {
  const currentDate = new Date(dateToWeek);
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
  const weekNumber = Math.ceil(days / 7);
  return currentDate.getFullYear() + "-" + weekNumber;
};

const resultByDate = (arr) => {
  const resultByDateArr = [];
  arr.map((val) => {
    const foundDate = resultByDateArr.find(
      (curDate) => curDate.date === val.date
    );
    if (!foundDate) {
      resultByDateArr.push({
        date: val.date,
        leaks: val.verificationResult === "Leak" ? 1 : 0,
        suspected: val.verificationResult === "Suspected" ? 1 : 0,
      });
    } else {
      if (val.verificationResult === "Leak") {
        foundDate.leaks++;
      }
      if (val.verificationResult === "Suspected") {
        foundDate.suspected++;
      }
    }
  });
  return resultByDateArr.sort((a, b) => (a.date > b.date ? 1 : -1));
};

const resultsByDateCalculation = (arr) => {
  const { days, weeks, months } = resultsByDateGetDate(arr);
  const resultsByDateDays = resultByDate(days);
  const resultsByDateWeeks = resultByDate(weeks);
  const resultsByDateMonths = resultByDate(months);
  return { resultsByDateDays, resultsByDateWeeks, resultsByDateMonths };
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export const calculation = (
  mdcPilot,
  mdcFieldResTest,
  baseValues,
  defaultMetricsValues
) => {
  const meter = defaultMetricsValues.meter;
  const service = defaultMetricsValues.service;
  const pipe = defaultMetricsValues.pipe;
  const valve = defaultMetricsValues.valve;
  const fireHydrant = defaultMetricsValues.hydrant;
  const connectionsFittings = defaultMetricsValues.customer_fitting;
  const curbstop = defaultMetricsValues.curbstop;
  const customerSide = defaultMetricsValues.customer_side;

  const waterCost = baseValues.water_cost;
  const powerCost = baseValues.power_cost;
  const treatmentCost = baseValues.treatment_cost;
  const greenhouseGasEmissions = baseValues.greenhouse_gas_emissions;
  const energyPerLiter = baseValues.energy_per_liter;

  const totalLeaksFound = totalLeaksFoundCalculation(mdcFieldResTest);
  const pipeLengthInvestigated =
    pipeLengthInvestigatedCalculation(mdcPilot).toFixed(0);

  let leaksPerKm = 0;
  if (pipeLengthInvestigated > 0) {
    leaksPerKm = (totalLeaksFound / pipeLengthInvestigated).toFixed(1);
  } else if (pipeLengthInvestigated === 0) {
    leaksPerKm = 0;
  }

  const numberOfPoiTypes = numberOfPoiTypesCalculation(mdcPilot);
  const numberOfPoiInvestigated =
    numberOfPoiTypes.numberOfLeaksPoi +
    numberOfPoiTypes.numberOfSuspectedPoi +
    numberOfPoiTypes.numberOfQuietPoi;
  const numberOfTotalPoi = numberOfTotalPoiCalculation(mdcPilot);
  const pipeLengthTotal = pipeLengthTotalCalculation(mdcPilot).toFixed(0);

  let pipeInvestigatedPercentage = 0;
  if (pipeLengthInvestigated > 0) {
    pipeInvestigatedPercentage = (
      (pipeLengthInvestigated / pipeLengthTotal) *
      100
    ).toFixed(0);
  } else if (pipeLengthInvestigated === 0) {
    pipeInvestigatedPercentage = 0;
  }

  const numberOfLeaksVisibleNo =
    numberOfLeaksVisibleNoCalculation(mdcFieldResTest);
  const crewDays = crewDaysCalculation(mdcFieldResTest).toFixed(1);
  const totalLeaksWithoutWo = totalLeaksWithoutWoCalculation(mdcFieldResTest);
  let leaksPerCrewDays = 0;
  if (crewDays > 0) {
    leaksPerCrewDays = (totalLeaksWithoutWo / crewDays).toFixed(1);
  }
  const waterSave = waterSaveCalculation(
    mdcFieldResTest,
    service,
    meter,
    connectionsFittings,
    fireHydrant,
    valve,
    pipe,
    curbstop,
    customerSide
  ).toFixed(0);
  const waterCostSave = (waterSave * waterCost).toFixed(0);
  const energySave = (waterSave * energyPerLiter).toFixed(0);
  const energyCostSave = (energySave * powerCost).toFixed(0);
  const greenhouseGasReduction = (
    energySave * greenhouseGasEmissions
  ).toFixed();
  const leakTypeTotal = leakTypeTotalCalculation(mdcFieldResTest);
  const leakTypeMain = leakTypeMainCalculation(mdcFieldResTest);
  const leakTypeService = leakTypeServiceCalculation(mdcFieldResTest);
  const leakTypeCustomerSide = leakTypeCustomerSideCalculation(mdcFieldResTest);
  const leaksPerPoiPerLicense =
    leaksPerPoiPerLicenseCalculation(mdcFieldResTest);
  const resultsByDate = resultsByDateCalculation(mdcFieldResTest);

  return {
    totalLeaksFound,
    leaksPerKm,
    pipeInvestigatedPercentage,
    numberOfLeaksVisibleNo,
    numberOfPoiInvestigated,
    numberOfTotalPoi,
    pipeLengthInvestigated,
    pipeLengthTotal,
    numberOfLeaksVisibleNo,
    leaksPerCrewDays,
    crewDays,
    greenhouseGasReduction,
    waterSave,
    waterCostSave,
    energySave,
    energyCostSave,
    numberOfPoiTypes,
    leakTypeTotal,
    leakTypeMain,
    leakTypeService,
    leakTypeCustomerSide,
    leaksPerPoiPerLicense,
    resultsByDate,
  };
};
