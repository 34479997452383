import React from "react";
import axios from "axios";
import FileSaver from "file-saver";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setLoadingMsg,
  setSnackBarMsg,
} from "../../store/ducks/general";
import { createPdfFromDom } from "../utilities/utilities";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { exportToExcelFile } from "../../../utilities/exportToExcel";
import { userRoles } from "../utilities/utilities";

const style = {
  container: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    border: "2px solid #393939",
    color: "#212121",
    fontSize: "15px",
    zIndex: "1",
    height: "auto",
    width: "85px",
    marginLeft: "470px",
    position: "absolute",
    backgroundColor: "#000",
  },
};

////////////////////////////////////////////////// Sub Methods //////////////////////////////////////////////////

// Converts a base64 string into a blob
function base64ToBlob(base64String) {
  const byteCharacters = window.atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray]);
}

// Function that creates an image using the dom element and inserting it all to a pdf
async function CreatePdf(projectName) {
  const fileName = `${projectName}_Dashboard_${Moment().format(
    "DD_MM_YYYY_hh_mm_ss"
  )}`;

  // The properties for dom element
  const htmlClass = {
    id: "bench",
    rotation: 0,
    lMargin: 5,
    tMargin: 15,
    width: 200,
    height: 280,
  };

  // html Extraction
  const html = window.document.getElementsByName(htmlClass.id)[0];

  createPdfFromDom(html, fileName, htmlClass, "dashboard", projectName);

  return true;
}

////////////////////////////////////////////////// Main Method //////////////////////////////////////////////////

// Dropdown with all the export buttons
const ExportDropdown = (data) => {
  const { t } = useTranslation();

  const loginValues = useSelector((state) => state.general.loginValues);
  const dispatch = useDispatch();

  async function exportData(type) {
    if (loginValues.role == userRoles.CLIENT_VIEWER) {
      return;
    }
    // Creates a pdf with html elements and initiates download
    if (type == "pdf") {
      CreatePdf(data.projectName, dispatch);
    } else {
      // Deciding which data to send with the request
      let dataToSend = loginValues.subProject;

      if (type === "excel") {
        dispatch(setLoading(true));
        dispatch(
          setLoadingMsg("Please wait while generating the Excel report.")
        );
        await exportToExcelFile(
          data.projectName,
          data.originalDataBeforeFilter,
          t
        );
        dispatch(setLoading(false));
        return;
      } else if (type === "photos") {
        // data.setPhotosModalIsOpen(true);
        dispatch(setLoading(true));
        dispatch(
          setLoadingMsg(
            "Please wait while generating the Zip file with the photos."
          )
        );
      }

      axios
        .get("/export/" + type + "/" + dataToSend + "/" + loginValues.userId)
        .then((res) => {
          let fileName = "";
          let blob = null;
          // Initiating logic according to the type of export
          switch (type) {
            case "kml":
              fileName =
                data.projectName +
                "_Results_" +
                Moment().format("DD_MM_YYYY_hh_mm_ss") +
                ".";
              fileName += "kml";
              // Creating a blob from a binary string and initiating download of it
              blob = new Blob([res.data]);
              FileSaver.saveAs(blob, fileName);
              break;
            default:
              dispatch(setLoading(false));
              if (res.data === false) {
                dispatch(setSnackBarMsg("No Photos exist"));
              } else {
                // data.setPhotosModalIsOpen(true);
                fileName =
                  data.projectName +
                  "_Photos_" +
                  Moment().format("DD_MM_YYYY_hh_mm_ss") +
                  ".";
                fileName += "zip";
                // Creating a blob from a byteArray string and initiating download of it
                blob = new Blob([new Uint8Array(res.data)]);
                FileSaver.saveAs(blob, fileName);
              }
              break;
          }
        });
    }
  }
  return (
    <div style={style.container}>
      <button
        className="export-dropdown-cell"
        onClick={(e) => {
          exportData("pdf");
        }}
      >
        <a>PDF</a>
      </button>
      <button
        className="export-dropdown-cell"
        onClick={(e) => {
          exportData("excel");
        }}
      >
        <a>Excel</a>
      </button>
      <button
        className="export-dropdown-cell"
        onClick={(e) => {
          exportData("kml");
        }}
      >
        <a>KML</a>
      </button>
      <button
        className="export-dropdown-cell"
        onClick={(e) => {
          exportData("photos");
        }}
      >
        <a>Photos</a>
      </button>
    </div>
  );
};

export default ExportDropdown;
