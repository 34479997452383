import React from "react";

const styles = {
  btn: {
    color: "#FFFFFF",
    padding: "10px",
    fontSize: "17px",
  },
  mainDiv: {
    textAlign: "center",
    width: "100%",
  },
};

const Pagination = (props) => {
  const currentPage = props.currentPage;
  const postPerPage = props.postPerPage;
  const totalRows = props.fieldResultsWoFiltered?.length;
  const totalPagesNumbers = totalRows ? Math.ceil(totalRows / postPerPage) : 0;

  const handleNextPage = (e) => {
    e.preventDefault();
    if (currentPage + 1 <= totalPagesNumbers) {
      props.setCurrentPage(currentPage + 1);
    } else {
      props.setCurrentPage(1);
    }
  };

  const handlePerviousPage = (e) => {
    e.preventDefault();
    if (currentPage - 1 >= 1) {
      props.setCurrentPage(currentPage - 1);
    } else {
      props.setCurrentPage(totalPagesNumbers);
    }
  };

  return (
    <div style={styles.mainDiv}>
      <button onClick={handlePerviousPage} style={styles.btn}>
        &#xab;
      </button>
      <span style={styles.btn}>{currentPage}</span>
      <button onClick={handleNextPage} style={styles.btn}>
        &#xbb;
      </button>
    </div>
  );
};

export default Pagination;
