import { useEffect } from "react";
import L from "leaflet";
import "./leaflet-create-map.css";
import homeIcon from "../../images/homeIcon.png";
import fullscreenIcon from "../../images/fullscreenIcon.png";
import { useTranslation } from "gatsby-plugin-react-i18next";

function CreateMap({ map, bounds }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (map) {
      const hereTechMap = new L.tileLayer(
        "https://{s}.{base}.maps.ls.hereapi.com/maptile/2.1/{type}/{mapID}/hybrid.day/{z}/{x}/{y}/{size}/{format}?apiKey={api_key}&lg={language}",
        {
          attribution:
            '<a href="http://developer.here.com">HERE</a> Map &copy; 1987-2014 ',
          subdomains: "1234",
          mapID: "newest",
          api_key: process.env.GATSBY_HERE_API_KEY,
          base: "aerial",
          maxZoom: 20,
          zoomAnimation: true,
          type: "maptile",
          language: "eng",
          format: "png8",
          zoomControl: false,
          size: "256",
        }
      );
      // lefleat map street map
      const osmMap = new L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          maxZoom: 20,
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors ',
          id: "",
        }
      );

      const baseLayers = { Map: osmMap, "Satellite ": hereTechMap };
      L.control.layers(baseLayers).addTo(map);
      osmMap.addTo(map); //  set as default

      const legend = L.control({ position: "bottomright" });
      legend.onAdd = () => {
        const div = L.DomUtil.create("div", "info legend");
        div.innerHTML = `
        <div class="legend-con">
          <div class="legend-color" style="background-color:#ffc000;"></div>
          <div style="float:left;"><strong>POI</strong></div>
        </div>
        <div class="legend-con">
          <div class="legend-color" style="background-color:#FF7F50;"></div>
          <div style="float:left;"><strong>${t("quiet")}</strong></div>
        </div>
        <div class="legend-con">
          <div class="legend-color" style="background-color:#DDA0DD;"></div>
          <div style="float:left;"><strong>${t("unverifiable")}</strong></div>
        </div>
        <div class="legend-con">
          <div style="float:left;   padding: 10px 0px 10px 0px;"><strong>${t(
            "field-results.investigation-results"
          )}</strong></div>
        </div>
        <div class="legend-con">
          <div class="legend-color" style="background-color:green;"></div>
          <div style="float:left;"><strong>${t("leak")}</strong></div>
        </div>
        <div class="legend-con">
          <div class="legend-color" style="background-color:yellow;"></div>
          <div style="float:left;"><strong>${t("suspected")}</strong></div>
        </div>
        <div class="legend-con">
          <div class="legend-color" style="background-color:#04D3CC;"></div>
          <div style="float:left;"><strong>${t("repaired")}</strong></div>
        </div>
      `;

        return div;
      };

      legend.addTo(map);

      // Home Button
      L.Control.Button = L.Control.extend({
        options: {
          position: "topleft",
        },
        onAdd: function (map) {
          var container = L.DomUtil.create(
            "div",
            "leaflet-bar leaflet-control"
          );
          var button = L.DomUtil.create(
            "a",
            "leaflet-control-button",
            container
          );
          let icon = L.DomUtil.create("img", "leaflet-image", button);
          icon.src = homeIcon;
          L.DomEvent.disableClickPropagation(button);
          L.DomEvent.on(button, "click", function () {
            map.fitBounds(bounds);
          });

          container.title = "Home";

          return container;
        },
        onRemove: function (map) {},
      });
      var control = new L.Control.Button();
      control.addTo(map);

      L.control
        .fullscreen({
          position: "topleft", // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
          title: "Show me the fullscreen !", // change the title of the button, default Full Screen
          content: `<img src=${fullscreenIcon} style='padding-top: 0.5em'/>`, // change the content of the button, can be HTML, default null
          forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
          fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to map._container
        })
        .addTo(map);
    }
  }, [map]);
  return null;
}

export default CreateMap;
