import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PhotoModal from "./photo-modal";
import FilterDataType from "./filter-data-type";

import {
  TotalLeaksFoundCard,
  LeaksPerMileCard,
  NonSurfacingLeaksCard,
  LeaksPerCrewDayCard,
  GreenhouseGasReductionCard,
  WaterSavedCard,
  WaterCostSavingsCard,
  EnergySavedCard,
  EnergyCostSavingsCard,
  CompletedPoisCard,
  PipeInvestigatedCard,
  InvestigatedPoisCard,
} from "../cards/performance-cards";

import LeakTypeCard from "../cards/leak-type-card";
import LeakPerLicenseCard from "../cards/leak-per-license-card";
import ResultsByDateCard from "../cards/results-by-date-card";
import LeafletMap from "../layouts/leaflet-map";
import FieldResultsChart from "../layouts/field-results";
import { userRoles } from "../utilities/utilities";
import { useState, useRef } from "react";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";

const CustomTooltip = styled(ReactTooltip)`
  max-width: 300px;
  background-color: #505050 !important;
  padding: 10px !important;
`;
const Container = styled.section`
  .title-row {
    padding: 0 0 1.5rem 0;
  }
`;

const Benchmarks = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  padding: 0 0 2.5rem 0;
  .left-column,
  .right-column {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
  .left-column {
    grid-template-rows: repeat(5, 1fr);
  }
  .right-column {
    grid-template-rows: 3.05fr 2fr;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    grid-template-columns: 1fr;
  }
`;
const Benchmarks2 = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 0 0 25px 0;
  .left-column,
  .right-column {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
  .left-column {
    grid-template-rows: repeat(5, 1fr);
  }
  .right-column {
    grid-template-rows: 3.05fr 2fr;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

const Results = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

const Results2 = styled.section`
  display: flex;
  justify-content: space-between;
`;
const LeakType = styled.section``;

const ResultsByDate = styled.section``;

const LeaksPerLicense = styled.section``;

const UCollectResults = styled.div``;

const UCollectResultsByDate = styled.section`
  padding: 2rem 0 0 0;
`;
const Map = styled.section`
  @media (max-width: ${(props) => props.theme.breakPoints.xxl}) {
    padding: 1rem 0 2rem 0;
  }
`;

const Performance = (props) => {
  const calculationResult = props.calculationResult;
  const [map, setMap] = useState(null);
  const [imageArraySrc, setImageArraySrc] = useState();
  const [circlesResult, setCirclesResult] = useState([]);
  const [scrollToMap, setScrollToMap] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const scrollToRef = useRef();

  const { t } = useTranslation();
  useEffect(() => {
    if (scrollToMap) {
      scrollToRef.current.scrollIntoView();
      setScrollToMap(false);
    }
  }, [scrollToMap]);

  return (
    <>
      {/* Start of PDF container */}
      <div
        name="bench"
        style={{
          position: "absolute",
          zIndex: "-999999",
          width: "1120px",
          height: "1000px",
        }}
      >
        <Benchmarks2
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="left-column" style={{ width: "550px" }}>
            <TotalLeaksFoundCard
              pdf={false}
              totalLeaksFound={calculationResult.totalLeaksFound}
            />

            <LeaksPerMileCard
              pdf={false}
              leaksPerkmMile={calculationResult.leaksPerKm}
            />
            <NonSurfacingLeaksCard
              pdf={false}
              totalLeaksFound={calculationResult.totalLeaksFound}
              nonSurfacingLeaks={calculationResult.numberOfLeaksVisibleNo}
              width={"wide"}
            />
            <LeaksPerCrewDayCard
              pdf={false}
              leaksPerCrewDays={calculationResult.leaksPerCrewDays}
            />
            <GreenhouseGasReductionCard
              pdf={false}
              greenhouseSaved={calculationResult.greenhouseGasReduction}
            />
            <WaterSavedCard
              pdf={false}
              waterSaved={calculationResult.waterSave}
            />
            <WaterCostSavingsCard
              pdf={false}
              waterCostSaved={calculationResult.waterCostSave}
            />
            <EnergySavedCard
              pdf={false}
              energySaved={calculationResult.energySave}
            />
            <EnergyCostSavingsCard
              pdf={false}
              energyCostSaved={calculationResult.energyCostSave}
            />
          </div>
          <div className="right-column" style={{ width: "550px" }}>
            <CompletedPoisCard
              pdf={false}
              investigatePoi={calculationResult.numberOfPoiInvestigated}
              totalPoi={calculationResult.numberOfTotalPoi}
            />
            <PipeInvestigatedCard
              pdf={false}
              pipeInvestigated={calculationResult.pipeLengthInvestigated}
              totalPipe={calculationResult.pipeLengthTotal}
              pipeInvestigatedPercentage={
                calculationResult.pipeInvestigatedPercentage
              }
            />
            <InvestigatedPoisCard
              crewDays={calculationResult.crewDays}
              pdf={true}
              numberOfPoiTypes={calculationResult.numberOfPoiTypes}
            />
          </div>
        </Benchmarks2>
        {props.role != userRoles.CLIENT_VIEWER && (
          <UCollectResults style={{ width: "1120px", height: "860px" }}>
            <Results2 style={{ width: "1120px", height: "400px" }}>
              <LeakType>
                <div className="title-row">
                  <h2>{t("leak-type.h2")}</h2>
                </div>
                <LeakTypeCard
                  leakTypeTotal={calculationResult.leakTypeTotal}
                  leakTypeCustomerSide={calculationResult.leakTypeCustomerSide}
                  leakTypeMain={calculationResult.leakTypeMain}
                  leakTypeService={calculationResult.leakTypeService}
                  role={props.role}
                  projectName={props.projectName}
                  pdf={true}
                />
              </LeakType>
              <LeaksPerLicense>
                <div className="title-row">
                  <h2>{t("leaks-poi-per-license.h2")}</h2>
                </div>
                <LeakPerLicenseCard
                  role={props.role}
                  pdf={true}
                  leaksPerPoiPerLicense={
                    calculationResult.leaksPerPoiPerLicense
                  }
                />
              </LeaksPerLicense>
            </Results2>
            <UCollectResultsByDate>
              <div className="title-row">
                <h2>{t("results-by-date.h2")}</h2>
              </div>
              <ResultsByDateCard
                resultsByDate={calculationResult.resultsByDate}
                role={props.role}
                pdf={true}
                projectName={props.projectName}
              />
            </UCollectResultsByDate>
          </UCollectResults>
        )}
      </div>
      {/* End of PDF container */}
      <Container>
        <FilterDataType values={props} />
        <div className="title-row">
          <span data-tip="Metrics are calculated based on leaks found during field investigation. Leaks discovered outside of ASTERRA highlighted pipes (IDs beginning in L) are noted on map and table but are not included in the pipe length investigated.">
            <h2>{t("project-benchmarks.h2")}</h2>
          </span>
          <CustomTooltip />
        </div>
        <div>
          <Benchmarks>
            <div className="left-column">
              <TotalLeaksFoundCard
                pdf={false}
                totalLeaksFound={calculationResult.totalLeaksFound}
              />
              <LeaksPerMileCard
                pdf={false}
                leaksPerkmMile={calculationResult.leaksPerKm}
              />
              <NonSurfacingLeaksCard
                pdf={false}
                totalLeaksFound={calculationResult.totalLeaksFound}
                nonSurfacingLeaks={calculationResult.numberOfLeaksVisibleNo}
                width={"wide"}
              />
              <LeaksPerCrewDayCard
                pdf={false}
                leaksPerCrewDays={calculationResult.leaksPerCrewDays}
              />
              <GreenhouseGasReductionCard
                pdf={false}
                greenhouseSaved={calculationResult.greenhouseGasReduction}
              />
              <WaterSavedCard
                pdf={false}
                waterSaved={calculationResult.waterSave}
              />
              <WaterCostSavingsCard
                pdf={false}
                waterCostSaved={calculationResult.waterCostSave}
              />
              <EnergySavedCard
                pdf={false}
                energySaved={calculationResult.energySave}
              />
              <EnergyCostSavingsCard
                pdf={false}
                energyCostSaved={calculationResult.energyCostSave}
              />
            </div>
            <div className="right-column">
              <CompletedPoisCard
                pdf={false}
                investigatePoi={calculationResult.numberOfPoiInvestigated}
                totalPoi={calculationResult.numberOfTotalPoi}
              />
              <PipeInvestigatedCard
                pdf={false}
                pipeInvestigated={calculationResult.pipeLengthInvestigated}
                totalPipe={calculationResult.pipeLengthTotal}
                pipeInvestigatedPercentage={
                  calculationResult.pipeInvestigatedPercentage
                }
              />
              <InvestigatedPoisCard
                crewDays={calculationResult.crewDays}
                pdf={false}
                numberOfPoiTypes={calculationResult.numberOfPoiTypes}
              />
            </div>
          </Benchmarks>
          {props.role != userRoles.CLIENT_VIEWER && (
            <UCollectResults>
              <Results>
                <LeakType>
                  <div className="title-row">
                    <h2>{t("leak-type.h2")}</h2>
                  </div>
                  <LeakTypeCard
                    leakTypeTotal={calculationResult.leakTypeTotal}
                    leakTypeCustomerSide={
                      calculationResult.leakTypeCustomerSide
                    }
                    leakTypeService={calculationResult.leakTypeService}
                    leakTypeMain={calculationResult.leakTypeMain}
                    role={props.role}
                    projectName={props.projectName}
                    pdf={false}
                  />
                </LeakType>
                <LeaksPerLicense>
                  <div className="title-row">
                    <h2>{t("leaks-poi-per-license.h2")}</h2>
                  </div>
                  <LeakPerLicenseCard
                    role={props.role}
                    pdf={false}
                    leaksPerPoiPerLicense={
                      calculationResult.leaksPerPoiPerLicense
                    }
                  />
                </LeaksPerLicense>
              </Results>
              <UCollectResultsByDate>
                <div className="title-row">
                  <h2>{t("results-by-date.h2")}</h2>
                </div>
                <ResultsByDateCard
                  resultsByDate={calculationResult.resultsByDate}
                  role={props.role}
                  pdf={false}
                  projectName={props.projectName}
                />
              </UCollectResultsByDate>
            </UCollectResults>
          )}
        </div>

        {showImage && (
          <PhotoModal
            imageArraySrc={imageArraySrc}
            setShowImage={setShowImage}
          />
        )}
        <Map ref={scrollToRef}>
          <LeafletMap
            map={map}
            setMap={setMap}
            mapData={props}
            setImageArraySrc={setImageArraySrc}
            setCirclesResult={setCirclesResult}
            circlesResult={circlesResult}
            setShowImage={setShowImage}
            filteredData={props.filteredData}
          />
        </Map>
        <FieldResultsChart
          fieldData={props.fieldResults}
          circlesResult={circlesResult}
          map={map}
          setScrollToMap={setScrollToMap}
          setShowImage={setShowImage}
          setImageArraySrc={setImageArraySrc}
          filteredData={props.filteredData}
        />
      </Container>
    </>
  );
};

export default Performance;
