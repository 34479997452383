import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import Layout from "../../components/layout";
import Wrapper from "../../components/layouts/standard-wrapper";
import Assets from "../../components/layouts/assets";
import ReactModal from "react-modal";
import CloseIcon from "../../images/icons/close.svg";
import { CopyButton } from "../../../src/components/buttons/buttons";
import Benchmarks from "../../../src/components/layouts/project-benchmarks";
import { DashboardBackButton } from "../../components/buttons/buttons";
import ReactTooltip from "react-tooltip";

import { calculation } from "../../../utilities/calculations";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../store/ducks/general";
import { login, addSubProject } from "../../store/ducks/general";
import moment from "moment";
import UserRoute from "../../components/routes/userRoute";
import FileSaver from "file-saver";
import DummyFieldWhole from "./DummyFieldWhole.json";

const Container = styled.div``;

const Header = styled.section`
  background: ${(props) => props.theme.colors.primaryColors.blue};
  padding: 1.25rem 3rem 2rem 3rem;
  .u-collect-navigation-row {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 0.75rem;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
  .u-collect-status-row {
    padding: 3rem 0 0 0;
    display: flex;
    justify-content: space-between;
  }
  .u-collect-progress-container {
    border-radius: 30px;
    border: 1px solid ${(props) => props.theme.colors.primaryColors.white};
    background: ${(props) => props.theme.colors.primaryColors.white}30;
    height: 45px;
    width: 350px;
  }
  .u-collect-progress-bar-inner-large,
  .u-collect-progress-bar-inner-small {
    border-radius: 30px;
    background: ${(props) => props.theme.colors.primaryColors.white};
    color: ${(props) => props.theme.colors.primaryColors.black};
    height: 100%;
    display: flex;
    align-items: center;
    p {
      font-weight: 500;
      font-size: 1.25rem;
      padding: 0 0 0 1rem;
    }
  }
  .u-collect-progress-bar-inner-small {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    padding: 2rem 1.5rem;
    .u-collect-status-row {
      display: block;
    }
    .u-collect-progress-container {
      width: 100%;
      margin: 1rem 0 0 0;
    }
  }
`;

const Modal = styled.div`
  background: ${(props) => props.theme.variables.cards.dashboard.background};
  border: 1px solid ${(props) => props.theme.variables.cards.dashboard.border};
  color: ${(props) => props.theme.variables.color};
  border-radius: 3px;
  padding: 2.5rem 2rem;
  a {
    text-decoration: underline;
    color: ${(props) => props.theme.variables.color};
    &:hover {
      color: ${(props) => props.theme.colors.secondaryColors.orange};
    }
  }
  .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 1rem 0;
  }
  .disclaimer {
    font-size: 0.5rem;
    margin: 1rem 0 0 0;
  }
  .username-row,
  .password-row {
    display: flex;
    align-items: center;
  }
  .wms-row {
    padding: 0 1rem 0 0;
    display: flex;
    align-items: center;
  }
  .wms-url {
    background: ${(props) => props.theme.colors.primaryColors.white};
    color: ${(props) => props.theme.colors.primaryColors.black};
    padding: 0.2rem 0.5rem;
    margin: 0 0.5rem 0 0;
  }
  button.close-modal-button {
    position: absolute;
    inset: 1rem 1rem auto auto;
    background: none;
    border: none;
    line {
      transition: all 250ms;
    }
    &:hover {
      line {
        stroke: ${(props) => props.theme.colors.secondaryColors.orange};
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    width: 350px;
    padding: 2rem;
  }
  .photos-menu {
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background: ${(props) => props.theme.colors.uiPalette.dark.darkGray};
      border: 1px solid
        ${(props) => props.theme.variables.buttons.workOrder.border};
    }
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.variables.buttons.workOrder.color};
      border-radius: 10px;
    }
    &:hover {
      overflow-y: scroll;
    }
  }
`;

const Recover = styled.section`
  .edit-button {
    font-size: 0.8125rem;
    font-weight: 500;
    border: none;
    background: none;
    color: ${(props) => props.theme.variables.color};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    .icon {
      margin: 0 0.5rem 0;
    }
    path {
      transition: all 250ms;
    }
    &:hover {
      color: ${(props) => props.theme.colors.secondaryColors.orange};
      path {
        fill: ${(props) => props.theme.colors.secondaryColors.orange};
      }
    }
  }
`;

const UCollectDashboard = () => {
  const [wo, setWo] = useState(false);
  const [calculationResult, setCalculationResult] = useState(null);
  const [filteredDataWithWo, setFilteredDataWithWo] = useState(null);
  const [filteredDataWithoutWo, setFilteredDataWithoutWo] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [originalDataBeforeFilter, setOriginalDataBeforeFilter] =
    useState(null);

  const woCheckHandler = () => {
    const newWo = !wo;
    if (newWo) {
      setFilteredData({ ...filteredDataWithoutWo });
    } else {
      setFilteredData({ ...filteredDataWithWo });
    }
    setWo(newWo);
  };

  useEffect(() => {
    if (filteredData) {
      setCalculationResult(
        calculation(
          filteredData.mdcPilot.data,
          filteredData.mdcFeildResTest.field_results,
          filteredData.baseValues,
          filteredData.defaultMetricsValues
        )
      );
    }
  }, [filteredData]);

  const [projectValues, setProjectValues] = useState("");
  const [photoNames, setPhotoNames] = useState("");

  const dispatch = useDispatch();
  const [loginValues, subProjectData] = useSelector((state) => [
    state.general.loginValues,
    state.general.projectData,
  ]);
  // const [showEditInputs, setShowEditInputs] = useState(false);
  const [releaseDates, setReleaseDates] = useState([]);

  // Toggling of WMS modal window
  const [wmsModalIsOpen, setWmsModalIsOpen] = useState(false);
  const toggleWmsModal = () => {
    setWmsModalIsOpen(!wmsModalIsOpen);
  };

  // Toggling of photos modal window
  const [photosModalIsOpen, setPhotosModalIsOpen] = useState(false);
  const togglePhotosModal = () => {
    setPhotosModalIsOpen(!photosModalIsOpen);
  };

  const goToSubProject = (subProjectData) => {
    if (loginValues.subProject !== subProjectData.sub_project) {
      dispatch(addSubProject(subProjectData.sub_project));
      window.localStorage.setItem("auth", JSON.stringify({ ...loginValues }));
      window.localStorage.setItem(
        "subProjectData",
        JSON.stringify(subProjectData)
      );
      dispatch(setLoading(false));
      setProjectValues("");
    }
  };

  useEffect(async () => {
    if (loginValues) {
      if (projectValues === "" && loginValues.token && loginValues.subProject) {
        // await getAllPhotosName();
        await getData();
        dispatch(setLoading(false));
      }
    }
  }, [loginValues && loginValues.subProject]);

  const nullToZero = (value) => {
    return value === null ? 0 : value;
  };

  const combineLeakType = (oldData, newData) => {
    if (newData !== null) {
      if (newData.length) {
        for (let i = 0; i < newData.length; i++) {
          Object.keys(newData[i]).forEach((key) => {
            let lowerKey = key.toLowerCase();
            lowerKey = lowerKey.replace("-", "_");
            oldData[lowerKey] += nullToZero(newData[i][key]);
          });
        }
      } else {
        Object.keys(newData).forEach((key) => {
          oldData[key] += nullToZero(newData[key]);
        });
      }
    }

    return oldData;
  };

  const combineData = (recover, field, mdc, projectName) => {
    let newRecover = {
      complete: 0,
      total_leaks_with_wo: 0,
      total_leaks_without_wo: 0,
      not_surface: 0,
      total_pipe_km_with_wo: 0,
      total_pipe_km_without_wo: 0,
      pipe_km_investigated_with_wo: 0,
      pipe_km_investigated_without_wo: 0,
      greenhouse_gas_reduction_with_wo: 0,
      greenhouse_gas_reduction_without_wo: 0,
      water_save_with_wo: 0,
      water_save_without_wo: 0,
      water_cost_savings_with_wo: 0,
      water_cost_savings_without_wo: 0,
      energy_saved_with_wo: 0,
      energy_saved_without_wo: 0,
      energy_cost_savings_with_wo: 0,
      energy_cost_savings_without_wo: 0,
      crew_days: 0,
      total_poi_with_wo: 0,
      total_poi_without_wo: 0,
      poi_investigated_with_wo: 0,
      poi_investigated_without_wo: 0,
      investigated_poi_leaks_with_wo: 0,
      investigated_poi_leaks_without_wo: 0,
      investigated_poi_quiet: 0,
      investigated_poi_suspected: 0,
      investigated_poi_unverifiable: 0,
      leak_type: {
        total: {
          total: 0,
          main: 0,
          service: 0,
          other: 0,
          customer_side: 0,
          WO: 0,
        },
        main: {
          connections_fittings: 0,
          fire_hydrant: 0,
          pipe: 0,
          valve: 0,
        },
        service: {
          connections_fittings: 0,
          curb_stop: 0,
          pipe: 0,
          meter: 0,
        },
        customer_side: {
          utility_loss: 0,
          pipe: 0,
          meter: 0,
          parks_and_recreation: 0,
        },
      },
      leak_type_without_wo: {
        total: {
          total: 0,
          main: 0,
          service: 0,
          other: 0,
          customer_side: 0,
        },
        main: {
          connections_fittings: 0,
          fire_hydrant: 0,
          pipe: 0,
          valve: 0,
        },
        service: {
          connections_fittings: 0,
          curb_stop: 0,
          pipe: 0,
          meter: 0,
        },
        customer_side: {
          utility_loss: 0,
          pipe: 0,
          meter: 0,
          parks_and_recreation: 0,
        },
      },
      leaks_per_poi_per_license: [],
      results_by_date_days_with_wo: [],
      results_by_date_weeks_with_wo: [],
      results_by_date_months_with_wo: [],
      results_by_date_days_without_wo: [],
      results_by_date_weeks_without_wo: [],
      results_by_date_months_without_wo: [],
    };

    for (let i = 0; i < recover.length; i++) {
      let totalLeakTypeWihoutWo = recover[i].leak_type_total_with_wo;
      delete totalLeakTypeWihoutWo.wo;

      newRecover.total_leaks_with_wo += nullToZero(
        recover[i].total_leaks_with_wo
      );
      newRecover.total_leaks_without_wo += nullToZero(
        recover[i].total_leaks_without_wo
      );
      newRecover.not_surface += nullToZero(recover[i].not_surface);
      newRecover.total_pipe_km_with_wo += nullToZero(
        recover[i].total_pipe_km_with_wo
      );
      newRecover.total_pipe_km_without_wo += nullToZero(
        recover[i].total_pipe_km_without_wo
      );
      newRecover.pipe_km_investigated_with_wo += nullToZero(
        recover[i].pipe_km_investigated_with_wo
      );
      newRecover.pipe_km_investigated_without_wo += nullToZero(
        recover[i].pipe_km_investigated_without_wo
      );
      newRecover.greenhouse_gas_reduction_with_wo += nullToZero(
        recover[i].greenhouse_gas_reduction_with_wo
      );
      newRecover.greenhouse_gas_reduction_without_wo += nullToZero(
        recover[i].greenhouse_gas_reduction_without_wo
      );
      newRecover.water_save_with_wo += nullToZero(
        recover[i].water_save_with_wo
      );
      newRecover.water_save_without_wo += nullToZero(
        recover[i].water_save_without_wo
      );
      newRecover.water_cost_savings_with_wo += nullToZero(
        recover[i].water_cost_savings_with_wo
      );
      newRecover.water_cost_savings_without_wo += nullToZero(
        recover[i].water_cost_savings_without_wo
      );
      newRecover.energy_saved_with_wo += nullToZero(
        recover[i].energy_saved_with_wo
      );
      newRecover.energy_saved_without_wo += nullToZero(
        recover[i].energy_saved_without_wo
      );
      newRecover.energy_cost_savings_with_wo += nullToZero(
        recover[i].energy_cost_savings_with_wo
      );
      newRecover.energy_cost_savings_without_wo += nullToZero(
        recover[i].energy_cost_savings_without_wo
      );
      newRecover.crew_days += nullToZero(recover[i].crew_days);
      newRecover.investigated_poi_leaks_with_wo += nullToZero(
        recover[i].investigated_poi_leaks_with_wo
      );
      newRecover.investigated_poi_leaks_without_wo += nullToZero(
        recover[i].investigated_poi_leaks_without_wo
      );
      newRecover.investigated_poi_quiet += nullToZero(
        recover[i].investigated_poi_quiet
      );
      newRecover.investigated_poi_suspected += nullToZero(
        recover[i].investigated_poi_suspected
      );
      newRecover.investigated_poi_unverifiable += nullToZero(
        recover[i].investigated_poi_unverifiable
      );
      newRecover.total_poi_with_wo += nullToZero(recover[i].total_poi_with_wo);
      newRecover.total_poi_without_wo += nullToZero(
        recover[i].total_poi_without_wo
      );
      newRecover.poi_investigated_with_wo += nullToZero(
        recover[i].poi_investigated_with_wo
      );
      newRecover.poi_investigated_without_wo += nullToZero(
        recover[i].poi_investigated_without_wo
      );
      newRecover.leak_type.total = combineLeakType(
        newRecover.leak_type.total,
        recover[i].leak_type_total_with_wo
      );
      newRecover.leak_type.main = combineLeakType(
        newRecover.leak_type.main,
        recover[i].leak_type_main
      );
      newRecover.leak_type.service = combineLeakType(
        newRecover.leak_type.service,
        recover[i].leak_type_service
      );
      newRecover.leak_type.customer_side = combineLeakType(
        newRecover.leak_type.customer_side,
        recover[i].leak_type_customer_side
      );
      newRecover.leak_type_without_wo.total = combineLeakType(
        newRecover.leak_type_without_wo.total,
        totalLeakTypeWihoutWo
      );
      newRecover.leak_type_without_wo.main = combineLeakType(
        newRecover.leak_type_without_wo.main,
        recover[i].leak_type_main
      );
      newRecover.leak_type_without_wo.service = combineLeakType(
        newRecover.leak_type_without_wo.service,
        recover[i].leak_type_service
      );
      newRecover.leak_type_without_wo.customer_side = combineLeakType(
        newRecover.leak_type_without_wo.customer_side,
        recover[i].leak_type_customer_side
      );
      if (Object.keys(recover[i].leaks_per_poi_per_license).length !== 0) {
        newRecover.leaks_per_poi_per_license.push(
          ...recover[i].leaks_per_poi_per_license
        );
      }
      if (recover[i].results_by_date_days_with_wo.data) {
        newRecover.results_by_date_days_with_wo.push(
          ...recover[i].results_by_date_days_with_wo.data
        );
        newRecover.results_by_date_weeks_with_wo.push(
          ...recover[i].results_by_date_weeks_with_wo.data
        );
        newRecover.results_by_date_months_with_wo.push(
          ...recover[i].results_by_date_months_with_wo.data
        );
      } else {
        newRecover.results_by_date_days_with_wo.push(
          ...recover[i].results_by_date_days_with_wo
        );
        newRecover.results_by_date_weeks_with_wo.push(
          ...recover[i].results_by_date_weeks_with_wo
        );
        newRecover.results_by_date_months_with_wo.push(
          ...recover[i].results_by_date_months_with_wo
        );
      }
      if (recover[i].results_by_date_days_without_wo.data) {
        newRecover.results_by_date_days_without_wo.push(
          ...recover[i].results_by_date_days_without_wo.data
        );
        newRecover.results_by_date_weeks_without_wo.push(
          ...recover[i].results_by_date_weeks_without_wo.data
        );
        newRecover.results_by_date_months_without_wo.push(
          ...recover[i].results_by_date_months_without_wo.data
        );
      } else {
        newRecover.results_by_date_days_without_wo.push(
          ...recover[i].results_by_date_days_without_wo
        );
        newRecover.results_by_date_weeks_without_wo.push(
          ...recover[i].results_by_date_weeks_without_wo
        );
        newRecover.results_by_date_months_without_wo.push(
          ...recover[i].results_by_date_months_without_wo
        );
      }
    }

    newRecover.complete += nullToZero(
      Number(
        (
          (newRecover.poi_investigated_with_wo / newRecover.total_poi_with_wo) *
          100
        ).toFixed(0)
      )
    );

    newRecover.complete /= recover.length;

    return newRecover;
  };

  const setPerformanceCardValues = (newBaseValues, oldBaseValues) => {
    setProjectValues({
      ...projectValues,
      project_values: {
        ...projectValues.project_values,
        water_cost_savings_with_wo:
          projectValues.project_values.water_cost_savings_with_wo *
          (newBaseValues.water_cost / oldBaseValues.lastWaterCostValue),
        water_cost_savings_without_wo:
          projectValues.project_values.water_cost_savings_without_wo *
          (newBaseValues.water_cost / oldBaseValues.lastWaterCostValue),
        energy_cost_savings_with_wo:
          projectValues.project_values.energy_cost_savings_with_wo *
          (newBaseValues.power_cost / oldBaseValues.lastPowerCostValue),
        energy_cost_savings_without_wo:
          projectValues.project_values.energy_cost_savings_without_wo *
          (newBaseValues.power_cost / oldBaseValues.lastPowerCostValue),
        greenhouse_gas_reduction_with_wo:
          projectValues.project_values.greenhouse_gas_reduction_with_wo *
          (newBaseValues.greenhouse_cost /
            oldBaseValues.lastGreenhouseCostValue),
        greenhouse_gas_reduction_without_wo:
          projectValues.project_values.greenhouse_gas_reduction_without_wo *
          (newBaseValues.greenhouse_cost /
            oldBaseValues.lastGreenhouseCostValue),
      },
    });
  };

  const getOnePhoto = async (value) => {
    dispatch(setLoading(true));

    const fileData = value.split("/");

    const res = await axios.get("get-photo", {
      params: { subProject: fileData[0], fileName: fileData[1] },
    });

    var blob = new Blob([new Uint8Array(res.data[fileData[1]].data)]);

    FileSaver.saveAs(blob, fileData[1]);

    dispatch(setLoading(false));
  };

  // const getAllPhotosName = async () => {
  //   const photoNamesResponse = await axios.get(`/get-photo-names`, {
  //     params: {
  //       subProject: loginValues.subProject,
  //     },
  //   });

  //   setPhotoNames(photoNamesResponse.data);
  // };

  const getLastRelease = (data) => {
    const arrayDatesLastRelease = [];
    data.data.map((value) => {
      if (!arrayDatesLastRelease.includes(value.release_date)) {
        arrayDatesLastRelease.push(value.release_date);
      }
    });
    return arrayDatesLastRelease;
  };

  const getData = async () => {
    try {
      dispatch(setLoading(true));
      const [
        projectName,
        recoverOverall,
        fieldResults,
        mdcPilotTable,
        baseValues,
        defaultMetricsValues,
      ] = await axios.all([
        axios.get(`/project-name`, {
          params: {
            subProjects: loginValues.subProject,
          },
        }),
        axios.get(`/recover-overall`, {
          params: {
            subProjects: loginValues.subProject,
            role: loginValues.role,
          },
        }),
        axios
          .get(`/field-results`, {
            params: {
              subProjects: loginValues.subProject,
            },
          })
          .then((res) => {
            if (res.data === "") {
              return DummyFieldWhole;
            } else {
              return res;
            }
          }),
        axios.get(`/mdc-pilot`, {
          params: {
            subProjects: loginValues.subProject,
          },
        }),
        axios.get(`/base-values`, {
          params: {
            subProjects: loginValues.subProject,
            userId: loginValues.userId,
          },
        }),
        axios.get(`/metrics-values`, {
          params: {
            userId: loginValues.userId,
            sub_project: loginValues.subProject,
          },
        }),
      ]);
      setOriginalDataBeforeFilter({
        mdcPilot: { ...mdcPilotTable.data },
        mdcFeildResTest: { ...fieldResults.data },
        baseValues: { ...baseValues.data },
        defaultMetricsValues: { ...defaultMetricsValues.data },
      });

      setFilteredDataWithWo({
        mdcPilot: { ...mdcPilotTable.data },
        mdcFeildResTest: { ...fieldResults.data },
        baseValues: { ...baseValues.data },
        defaultMetricsValues: { ...defaultMetricsValues.data },
      });

      const mdcPilotWithoutWo = { ...mdcPilotTable.data };
      mdcPilotWithoutWo.data = mdcPilotWithoutWo.data.filter((val) => {
        return val.verification_result === "Leak" && val.leak_type === "WO"
          ? false
          : true;
      });
      const mdcResWithoutWo = { ...fieldResults.data };
      mdcResWithoutWo.field_results = mdcResWithoutWo.field_results.filter(
        (val) => {
          return val.verification_result === "Leak" && val.leak_type === "WO"
            ? false
            : true;
        }
      );
      setFilteredDataWithoutWo({
        mdcPilot: { ...mdcPilotWithoutWo },
        mdcFeildResTest: { ...mdcResWithoutWo },
        baseValues: { ...baseValues.data },
        defaultMetricsValues: { ...defaultMetricsValues.data },
      });

      setFilteredData({
        mdcPilot: mdcPilotTable.data,
        mdcFeildResTest: fieldResults.data,
        baseValues: baseValues.data,
        defaultMetricsValues: defaultMetricsValues.data,
      });

      if (recoverOverall.data.length > 1) {
        projectName.data.date = moment().format("MMMM YYYY");
        projectName.data.name = "Master Dashboard";
        projectName.data.type = "";
      }
      const newRecover = combineData(recoverOverall.data, null, null, null);
      setReleaseDates(getLastRelease(mdcPilotTable.data));
      setProjectValues({
        project_values: newRecover,
        field_result: fieldResults.data == "" ? {} : fieldResults.data,
        mdc_pilot: mdcPilotTable.data,
        project_name: projectName.data,
        base_values: baseValues.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Translation component
  const { t } = useTranslation();
  return (
    <UserRoute>
      <Layout goToSubProject={goToSubProject}>
        <Container>
          <Header>
            <div className="u-collect-navigation-row">
              <DashboardBackButton />
              <p>{t("u-collect-dashboard")}</p>
            </div>
            <div className="u-collect-status-row">
              <div>
                {projectValues && (
                  <>
                    <h1>
                      {projectValues.project_name.name +
                        " " +
                        projectValues.project_name.type}
                    </h1>
                    {/* <p>{projectValues.project_name.date}</p> */}
                  </>
                )}
              </div>
              {!Array.isArray(loginValues.subProject) && (
                <div className="u-collect-progress-container">
                  {projectValues === "" ? (
                    ""
                  ) : (
                    <div
                      data-tip
                      data-for="progress-bar-tooltip"
                      data-tooltip-content={t("progress-bar-tooltip")}
                      className={
                        projectValues.project_values.complete < 20
                          ? "u-collect-progress-bar-inner-small"
                          : "u-collect-progress-bar-inner-large"
                      }
                      style={{
                        width:
                          projectValues.project_values.complete < 5
                            ? 0
                            : `${projectValues.project_values.complete}%`,
                      }}
                    >
                      <p>{projectValues.project_values.complete}%</p>
                      <ReactTooltip
                        id="progress-bar-tooltip"
                        type="dark"
                        place="bottom"
                        effect="solid"
                        className="tooltips"
                        backgroundColor={"#393939"}
                      >
                        <p style={{ fontSize: 13 }}>
                          {t("progress-bar-tooltip")}
                        </p>
                      </ReactTooltip>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Header>

          {projectValues === "" ? (
            ""
          ) : (
            <div>
              <Assets
                assetData={projectValues.project_values}
                setPerformanceCardValues={setPerformanceCardValues}
                setPhotosModalIsOpen={setPhotosModalIsOpen}
                projectName={projectValues.project_name.name}
                baseValues={projectValues.base_values}
                projectData={subProjectData}
                toggleWmsModal={toggleWmsModal}
                role={loginValues.role}
                setProjectValues={setProjectValues}
                woCheckHandler={woCheckHandler}
                wo={wo}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                originalDataBeforeFilter={originalDataBeforeFilter}
              />
            </div>
          )}
          {projectValues === "" ? (
            ""
          ) : (
            <Wrapper>
              <Benchmarks
                calculationResult={calculationResult}
                projectName={projectValues.project_name.name}
                benchmarkData={projectValues.project_values}
                dashboard={"u-collect"}
                fieldResults={projectValues.field_result}
                mdcPilot={projectValues.mdc_pilot}
                role={loginValues.role}
                releaseDates={releaseDates}
                projectValues={projectValues}
                setProjectValues={setProjectValues}
                originalDataBeforeFilter={originalDataBeforeFilter}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                setFilteredDataWithWo={setFilteredDataWithWo}
                setFilteredDataWithoutWo={setFilteredDataWithoutWo}
                wo={wo}
              />
            </Wrapper>
          )}
          <ReactModal
            isOpen={photosModalIsOpen}
            onRequestClose={togglePhotosModal}
            className={"modal-content"}
            overlayClassName={"modal-overlay"}
            ariaHideApp={false}
          >
            <Modal
              style={{
                height: "40rem",
                width: "20rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h2>
                <u>{t("photos-list")}</u>
              </h2>
              <ul
                className="photos-menu"
                style={{
                  marginTop: "10%",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {photoNames &&
                  photoNames.map((value) => {
                    return (
                      <li key={value}>
                        <button
                          onClick={() => {
                            getOnePhoto(value);
                          }}
                          style={{ color: "white" }}
                        >
                          {value}
                        </button>
                      </li>
                    );
                  })}
              </ul>
              <button
                onClick={togglePhotosModal}
                className="close-modal-button"
              >
                <CloseIcon aria-label="button" alt="Close icon" />
              </button>
            </Modal>
          </ReactModal>
          <ReactModal
            isOpen={wmsModalIsOpen}
            onRequestClose={toggleWmsModal}
            className={"modal-content"}
            overlayClassName={"modal-overlay"}
            ariaHideApp={false}
          >
            <Modal>
              <p className="modal-title">{t("wms.modal.wms")}</p>
              <div className="wms-row">
                <p className="wms-url">{subProjectData.wms}</p>
                <CopyButton
                  copyData={{ field: subProjectData.wms, type: "wms_link" }}
                />
              </div>
              <button onClick={toggleWmsModal} className="close-modal-button">
                <CloseIcon aria-label="button" alt="Close icon" />
              </button>
            </Modal>
          </ReactModal>
        </Container>
      </Layout>
    </UserRoute>
  );
};
export default UCollectDashboard;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: [
            "common"
            "U-Collect"
            "preformance"
            "measurements"
            "buttons"
            "field results"
            "navigation"
            "tags"
          ]
        }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
