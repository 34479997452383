import React from "react";
import { useState, useEffect } from "react";
import SelectBy from "./filter-data-select";
import { formatMonth } from "../utilities/utilities";

const FilterDataType = ({ values }) => {
  const [dataWithAndWithoutWo, setDataWithAndWithoutWo] = useState({});
  const [dataReleaseFilterArrSelected, setDataReleaseFilterArrSelected] =
    useState([]);
  const [leakTypeFilterArrSelected, setLeakTypeFilterArrSelected] = useState(
    []
  );
  const [timePeriodFilterArrSelected, setTimePeriodFilterArrSelected] =
    useState([]);

  useEffect(() => {
    setDataWithAndWithoutWo(originalDataWithAndWithoutWo());
  }, []);

  const dateSelectArr = values.releaseDates.map((val) => {
    return { value: val, label: val };
  });

  const setTimePeriod = () => {
    const date = new Date();
    const thisMonth = formatMonth(date.getMonth());
    const currentYear = date.getFullYear();
    const thisQuarter = Math.ceil(thisMonth / 3);
    const lastYear = currentYear - 1;
    const lastMonth =
      thisMonth - 1 === 0
        ? lastYear + "-12"
        : currentYear.toString() +
          "-" +
          formatMonth(date.getMonth() - 1).toString();
    const lastQuarter =
      thisQuarter - 1 === 0
        ? lastYear.toString() + "-4"
        : currentYear.toString() + "-" + (thisQuarter - 1).toString();
    return [
      { value: currentYear, label: "Current Year" },
      { value: lastYear, label: "Last Year" },
      { value: currentYear + "-" + thisMonth, label: "This Month" },
      { value: lastMonth, label: "Last Month" },
      { value: currentYear + "-" + thisQuarter, label: "This Quarter" },
      { value: lastQuarter, label: "Last Quarter" },
    ];
  };

  const timePeriodHandle = (val) => {
    setTimePeriodFilterArrSelected(val);
  };

  const setFilteredValues = (
    filterResultsWithWo,
    filterMDCWithWo,
    filterResultsWithoutWo,
    filterMDCWithoutWo
  ) => {
    const newFilterDataWithWo = createNewResultObject(
      filterResultsWithWo,
      filterMDCWithWo
    );
    const newFilterDataWithoutWo = createNewResultObject(
      filterResultsWithoutWo,
      filterMDCWithoutWo
    );
    values.setFilteredDataWithWo(newFilterDataWithWo);
    values.setFilteredDataWithoutWo(newFilterDataWithoutWo);

    if (values.wo) {
      values.setFilteredData(newFilterDataWithoutWo);
    } else {
      values.setFilteredData(newFilterDataWithWo);
    }
  };

  const leakTypeArr = [
    { value: "Main", label: "Main" },
    { value: "Service", label: "Service" },
    { value: "Customer-Side", label: "Customer Side" },
    { value: "WO", label: "Work Order" },
    { value: "Other", label: "Other" },
  ];

  const leakTypeHandle = (val) => {
    const arr = val.map((v) => v.value);
    setLeakTypeFilterArrSelected(arr);
  };

  const isYearIncludes = (time, year) => {
    return time.split("-")[0].toString() === year.toString() ? true : false;
  };

  const isQuarterIncludes = (time, quarter) => {
    const arrMonth = getQuarterMonths(quarter);
    const valMonth = time.split("-")[0] + "-" + time.split("-")[1];
    if (arrMonth.includes(valMonth)) {
      return true;
    }
    return false;
  };

  const getQuarterMonths = (quarter) => {
    const year = quarter.split("-")[0];
    let month1 = parseInt(quarter.split("-")[1]) * 3;
    let month2 = month1 - 1;
    let month3 = month2 - 1;
    month1 = month1 < 10 ? "0" + month1 : month1;
    month2 = month2 < 10 ? "0" + month2 : month2;
    month3 = month3 < 10 ? "0" + month3 : month3;
    const arrMonth = [
      year + "-" + month1,
      year + "-" + month2,
      year + "-" + month3,
    ];
    return arrMonth;
  };

  const createNewResultObject = (fieldResults, mdcPilot) => {
    return {
      baseValues: values.filteredData.baseValues,
      defaultMetricsValues: values.filteredData.defaultMetricsValues,
      mdcFeildResTest: {
        bounds_x: values.filteredData.mdcFeildResTest.bounds_x,
        bounds_y: values.filteredData.mdcFeildResTest.bounds_y,
        field_results: fieldResults,
      },
      mdcPilot: {
        bounds: values.filteredData.mdcPilot.bounds,
        data: mdcPilot,
      },
    };
  };

  const isMonthIncludes = (time, month) => {
    let valMonth = time.split("-");
    valMonth = valMonth[0] + "-" + valMonth[1];
    if (valMonth.toString() === month.toString()) {
      return true;
    }
    return false;
  };

  const convertDateFormat = (dateString) => {
    const dateStructure = dateString.split("-");
    const convertedDate = `${dateStructure[2]}-${dateStructure[1]}-${dateStructure[0]}`;
    return convertedDate;
  };

  const filterDataByTimePeriod = (
    filteredLeakTypefieldResultWithWo,
    filteredLeakTypefieldResultWithoutWo,
    filteredLeakTypeMdcPilotWithWo,
    filteredLeakTypeMdcPilotWithoutWo
  ) => {
    if (!timePeriodFilterArrSelected.length) {
      return {
        filteredTimePeriodfieldResultWithWo: filteredLeakTypefieldResultWithWo,
        filteredTimePeriodfieldResultWithoutWo:
          filteredLeakTypefieldResultWithoutWo,
        filteredTimePeriodMdcPilotWithWo: filteredLeakTypeMdcPilotWithWo,
        filteredTimePeriodMdcPilotWithoutWo: filteredLeakTypeMdcPilotWithoutWo,
      };
    }

    const filteredTimePeriodfieldResultWithWo =
      filteredLeakTypefieldResultWithWo.filter((fieldResult) =>
        isTimePeriodIncludes(fieldResult.timestamp_corrected)
      );

    const filteredTimePeriodfieldResultWithoutWo =
      filteredLeakTypefieldResultWithoutWo.filter((fieldResult) =>
        isTimePeriodIncludes(fieldResult.timestamp_corrected)
      );

    // Used to filter mdc pilot by all filtered results (WO)
    const fieldResultsFilteredUtiltsIdWithWo = new Set(
      filteredTimePeriodfieldResultWithWo.map((fieldResult) =>
        fieldResult.utilis_finding.trim()
      )
    );

    // Used to filter mdc pilot by all filtered results (Without WO)
    const fieldResultsFilteredUtiltsIdWithoutWo = new Set(
      filteredTimePeriodfieldResultWithoutWo.map((fieldResult) =>
        fieldResult.utilis_finding.trim()
      )
    );

    const filteredTimePeriodMdcPilotWithWo =
      filteredLeakTypeMdcPilotWithWo.filter((mdcPilot) => {
        return fieldResultsFilteredUtiltsIdWithWo.has(mdcPilot.utilis_id);
      });

    const filteredTimePeriodMdcPilotWithoutWo =
      filteredLeakTypeMdcPilotWithoutWo.filter((mdcPilot) => {
        return fieldResultsFilteredUtiltsIdWithoutWo.has(mdcPilot.utilis_id);
      });

    return {
      filteredTimePeriodfieldResultWithWo,
      filteredTimePeriodfieldResultWithoutWo,
      filteredTimePeriodMdcPilotWithWo,
      filteredTimePeriodMdcPilotWithoutWo,
    };
  };

  const isTimePeriodIncludes = (time) => {
    for (let dateSelected of timePeriodFilterArrSelected) {
      if (
        (dateSelected.label === "Current Year" ||
          dateSelected.label === "Last Year") &&
        isYearIncludes(time, dateSelected.value)
      ) {
        return true;
      } else if (
        (dateSelected.label === "This Month" ||
          dateSelected.label === "Last Month") &&
        isMonthIncludes(time, dateSelected.value)
      ) {
        return true;
      } else if (
        (dateSelected.label === "This Quarter" ||
          dateSelected.label === "Last Quarter") &&
        isQuarterIncludes(time, dateSelected.value)
      ) {
        return true;
      }
    }
    return false;
  };

  const filterDataReleaseArrays = (mdcPilot, fieldResults) => {
    const mdcPilotFiltered = mdcPilot.filter((mdc) =>
      dataReleaseFilterArrSelected.includes(mdc.release_date)
    );
    const utilisIds = new Set(mdcPilotFiltered.map((mdc) => mdc.utilis_id));
    const fieldResultFiltered = fieldResults.filter((fieldResult) =>
      utilisIds.has(fieldResult.utilis_finding.trim())
    );
    return { mdcPilotFiltered, fieldResultFiltered };
  };

  const filterDataByDataRelease = () => {
    if (!dataReleaseFilterArrSelected.length) {
      return {
        filteredDataReleaseMdcPilotWithWO: dataWithAndWithoutWo.mdcPilotWithWo,
        filteredDataReleaseMdcPilotWithoutWO:
          dataWithAndWithoutWo.mdcPilotWithoutWo,
        filteredDataReleasefieldResultWithWo:
          dataWithAndWithoutWo.fieldResultWithWo,
        filteredDataReleasefieldResultWithoutWo:
          dataWithAndWithoutWo.fieldResultWithoutWo,
      };
    }
    const {
      mdcPilotFiltered: filteredDataReleaseMdcPilotWithWO,
      fieldResultFiltered: filteredDataReleasefieldResultWithWo,
    } = filterDataReleaseArrays(
      dataWithAndWithoutWo.mdcPilotWithWo,
      dataWithAndWithoutWo.fieldResultWithWo
    );
    const {
      mdcPilotFiltered: filteredDataReleaseMdcPilotWithoutWO,
      fieldResultFiltered: filteredDataReleasefieldResultWithoutWo,
    } = filterDataReleaseArrays(
      dataWithAndWithoutWo.mdcPilotWithoutWo,
      dataWithAndWithoutWo.fieldResultWithoutWo
    );
    return {
      filteredDataReleaseMdcPilotWithWO,
      filteredDataReleaseMdcPilotWithoutWO,
      filteredDataReleasefieldResultWithWo,
      filteredDataReleasefieldResultWithoutWo,
    };
  };

  const filterLeakTypeArrays = (fieldResults, mdcPilot) => {
    const resFiltered = fieldResults.filter(
      (fieldResult) =>
        fieldResult.verification_result === "Leak" &&
        leakTypeFilterArrSelected.includes(fieldResult.leak_type)
    );
    const utilisIds = new Set(
      resFiltered.map((fieldResult) => fieldResult.utilis_finding.trim())
    );
    const resMDCFiltered = mdcPilot.filter((mdcValues) =>
      utilisIds.has(mdcValues.utilis_id)
    );
    return { resFiltered, resMDCFiltered };
  };
  const filterDataByLeakType = (
    filteredDataReleasefieldResultWithWo,
    filteredDataReleasefieldResultWithoutWo,
    filteredDataReleaseMdcPilotWithWO,
    filteredDataReleaseMdcPilotWithoutWO
  ) => {
    if (!leakTypeFilterArrSelected.length) {
      return {
        filteredLeakTypefieldResultWithWo: filteredDataReleasefieldResultWithWo,
        filteredLeakTypefieldResultWithoutWo:
          filteredDataReleasefieldResultWithoutWo,
        filteredLeakTypeMdcPilotWithWo: filteredDataReleaseMdcPilotWithWO,
        filteredLeakTypeMdcPilotWithoutWo: filteredDataReleaseMdcPilotWithoutWO,
      };
    }

    const {
      resFiltered: filteredLeakTypefieldResultWithWo,
      resMDCFiltered: filteredLeakTypeMdcPilotWithWo,
    } = filterLeakTypeArrays(
      filteredDataReleasefieldResultWithWo,
      filteredDataReleaseMdcPilotWithWO
    );
    const {
      resFiltered: filteredLeakTypefieldResultWithoutWo,
      resMDCFiltered: filteredLeakTypeMdcPilotWithoutWo,
    } = filterLeakTypeArrays(
      filteredDataReleasefieldResultWithoutWo,
      filteredDataReleaseMdcPilotWithoutWO
    );
    return {
      filteredLeakTypefieldResultWithWo,
      filteredLeakTypefieldResultWithoutWo,
      filteredLeakTypeMdcPilotWithWo,
      filteredLeakTypeMdcPilotWithoutWo,
    };
  };

  const originalDataWithAndWithoutWo = () => {
    const mdcPilotWithWo = [
      ...values.originalDataBeforeFilter.mdcPilot.data,
    ].map((mdc) => {
      return {
        ...mdc,
        convertedDate: convertDateFormat(mdc.release_date),
      };
    });
    const mdcPilotWithoutWo = mdcPilotWithWo.filter((mdcResult) =>
      mdcResult.leak_type === "WO" ? false : true
    );
    const fieldResultWithWo = [
      ...values.originalDataBeforeFilter.mdcFeildResTest.field_results,
    ];
    const fieldResultWithoutWo = fieldResultWithWo.filter((mdcResult) =>
      mdcResult.leak_type === "WO" ? false : true
    );

    return {
      mdcPilotWithWo,
      mdcPilotWithoutWo,
      fieldResultWithWo,
      fieldResultWithoutWo,
    };
  };

  const handleFilterData = () => {
    // filter 1 by data realse
    const {
      filteredDataReleaseMdcPilotWithWO,
      filteredDataReleaseMdcPilotWithoutWO,
      filteredDataReleasefieldResultWithWo,
      filteredDataReleasefieldResultWithoutWo,
    } = filterDataByDataRelease();

    // filter 2 by leak type
    const {
      filteredLeakTypefieldResultWithWo,
      filteredLeakTypefieldResultWithoutWo,
      filteredLeakTypeMdcPilotWithWo,
      filteredLeakTypeMdcPilotWithoutWo,
    } = filterDataByLeakType(
      filteredDataReleasefieldResultWithWo,
      filteredDataReleasefieldResultWithoutWo,
      filteredDataReleaseMdcPilotWithWO,
      filteredDataReleaseMdcPilotWithoutWO
    );

    // filter 3 by time period
    const {
      filteredTimePeriodfieldResultWithWo,
      filteredTimePeriodfieldResultWithoutWo,
      filteredTimePeriodMdcPilotWithWo,
      filteredTimePeriodMdcPilotWithoutWo,
    } = filterDataByTimePeriod(
      filteredLeakTypefieldResultWithWo,
      filteredLeakTypefieldResultWithoutWo,
      filteredLeakTypeMdcPilotWithWo,
      filteredLeakTypeMdcPilotWithoutWo
    );

    setFilteredValues(
      filteredTimePeriodfieldResultWithWo,
      filteredTimePeriodMdcPilotWithWo,
      filteredTimePeriodfieldResultWithoutWo,
      filteredTimePeriodMdcPilotWithoutWo
    );
  };

  useEffect(() => {
    if (
      leakTypeFilterArrSelected.length ||
      dataReleaseFilterArrSelected.length ||
      timePeriodFilterArrSelected.length
    ) {
      handleFilterData();
    } else if (dataWithAndWithoutWo?.mdcPilotWithWo?.length) {
      setFilteredValues(
        dataWithAndWithoutWo.fieldResultWithWo,
        dataWithAndWithoutWo.mdcPilotWithWo,
        dataWithAndWithoutWo.fieldResultWithoutWo,
        dataWithAndWithoutWo.mdcPilotWithoutWo
      );
    }
  }, [
    leakTypeFilterArrSelected,
    dataReleaseFilterArrSelected,
    timePeriodFilterArrSelected,
    dataWithAndWithoutWo,
  ]);

  const releaseDatesHandle = (val) => {
    const arr = val.map((v) => v.value);
    setDataReleaseFilterArrSelected(arr);
  };

  return (
    <div style={{ width: "100%", float: "left" }}>
      <SelectBy
        optionsHandler={dateSelectArr}
        changeHandler={releaseDatesHandle}
        text="Data Release"
      />
      <SelectBy
        optionsHandler={setTimePeriod()}
        changeHandler={timePeriodHandle}
        text="Time Period"
      />
      <SelectBy
        optionsHandler={leakTypeArr}
        changeHandler={leakTypeHandle}
        text="Leak Type"
      />
    </div>
  );
};

export default FilterDataType;
