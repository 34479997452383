import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import "leaflet/dist/leaflet.css";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
import { MapContainer, CircleMarker, Polygon, Popup } from "react-leaflet";

import CreateMap from "./leaflet-create-map";
import PopUpInfo from "./leaflet-popup-info";
import L from "leaflet";

const Container = styled.div`
  position: relative;
  margin: 2rem 0 0 0;
  .leaflet-container {
    width: 100%;
    height: 560px;
    z-index: 1;
  }
  .leaflet-control-zoom-out,
  .leaflet-control-zoom-in {
    color: black;
    background: ${(props) => props.theme.colors.primaryColors.white};
    color: ${(props) => props.theme.colors.primaryColors.gray};
    &:hover {
      color: ${(props) => props.theme.colors.primaryColors.red};
    }
  }
  .leaflet-touch .leaflet-control-layers,
  .leaflet-touch .leaflet-bar {
    border: none;
  }
  .leaflet-touch .leaflet-control-attribution {
    background: ${(props) => props.theme.colors.uiPalette.dark.midGray};
    color: ${(props) => props.theme.colors.primaryColors.white};
    a {
      color: ${(props) => props.theme.colors.primaryColors.white};
    }
  }
  .u-view-map-container {
    position: absolute;
    inset: auto auto 1rem 1rem;
    z-index: 2;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    .leaflet-container {
      height: 350px;
    }
  }
`;

const Map = (props) => {
  const {
    circlesResult,
    filteredData,
    map,
    mapData,
    setCirclesResult,
    setImageArraySrc,
    setMap,
    setShowImage,
  } = props;

  const [tableResults, setTableResults] = useState();
  const [mdcPilot, setMdcPilot] = useState();
  const [bounds, setBounds] = useState(null);
  const arrPopUp = [];
  const [isArrPopUpFull, setIsArrPopUpFull] = useState(false);

  useEffect(() => {
    const tableResults =
      props.filteredData.mdcFeildResTest.field_results?.filter((item) => {
        if (
          (item.verification_result === "Leak" ||
            item.verification_result === "Suspected") &&
          item.leak_type !== "WO"
        ) {
          return item.actual_x && item.actual_y;
        }
      });
    setTableResults(tableResults);
    setMdcPilot(filteredData.mdcPilot?.data);
    const fieldResBoundsX = filteredData.mdcFeildResTest?.bounds_x;
    const fieldResBoundsY = filteredData.mdcFeildResTest?.bounds_y;

    if (
      fieldResBoundsX?.actualXMin &&
      fieldResBoundsX?.actualXMax &&
      fieldResBoundsY?.actualYMin &&
      fieldResBoundsY?.actualYMax
    ) {
      let tempBounds = filteredData.mdcPilot?.bounds;

      tempBounds[0] = Math.min(tempBounds[0], fieldResBoundsX.actualXMin);
      tempBounds[1] = Math.max(tempBounds[1], fieldResBoundsX.actualXMax);
      tempBounds[2] = Math.min(tempBounds[2], fieldResBoundsY.actualYMin);
      tempBounds[3] = Math.max(tempBounds[3], fieldResBoundsY.actualYMax);

      setBounds(
        new L.latLngBounds(
          [tempBounds[2], tempBounds[0]],
          [tempBounds[3], tempBounds[1]]
        )
      );
    } else {
      const tempBounds = filteredData.mdcPilot?.bounds;
      setBounds(
        new L.latLngBounds(
          [tempBounds[2], tempBounds[0]],
          [tempBounds[3], tempBounds[1]]
        )
      );
    }
  }, [filteredData]);

  useEffect(() => {
    if (
      tableResults &&
      arrPopUp.length == tableResults.length &&
      circlesResult?.length == 0
    ) {
      setCirclesResult(arrPopUp);
    }
  }, [isArrPopUpFull]);

  // Translation component
  const { t } = useTranslation();

  // Toggling of modal window
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const getFillColor = (verificationResult, repaired, color) => {
    if (verificationResult === "Leak" && (repaired || "").toLowerCase() === "yes") {
      return "#04D3CC";
    } else {
      return color;
    }
  };

  // If server side rendering (SSR) then skip
  if (typeof window !== "undefined") {
    return (
      <>
        {tableResults && (
          <Container>
            <div className="leaflet-container">
              {bounds ? (
                <MapContainer
                  bounds={bounds}
                  whenCreated={setMap}
                  zoomControl={true}
                >
                  {mdcPilot
                    ? mdcPilot.map((mdcPilotRow, index) => {
                      return (
                        <Polygon
                          key={index}
                          pathOptions={mdcPilotRow?.color}
                          positions={mdcPilotRow?.geom}
                          weight={1.2}
                        >
                          <Popup key={index}>
                            Utilis Id : {mdcPilotRow?.utilis_id}
                            <br></br>
                            Pipe length : {mdcPilotRow?.pipe_lengt}
                          </Popup>
                        </Polygon>
                      );
                    })
                    : ""}
                  {tableResults
                    ? tableResults.map((tableRow, index) => (
                      <CircleMarker
                        key={`${tableRow?.actual_y}_${tableRow?.actual_x}_${index}`}
                        center={[tableRow?.actual_y, tableRow?.actual_x]}
                        radius={7}
                        color={tableRow?.colorBorder?.color}
                        fillColor={getFillColor(
                          tableRow?.verification_result,
                          tableRow?.repaired,
                          tableRow?.color?.color
                        )}
                        fillOpacity="1"
                        weight={2}
                      >
                        <Popup
                          key={`${tableRow?.actual_y} ${tableRow?.actual_x}`}
                          ref={(ref) => {
                            if (index < tableResults.length && ref != null) {
                              arrPopUp.push(ref);
                              if (index == tableResults.length - 1) {
                                setIsArrPopUpFull(true);
                              }
                            }
                          }}
                        >
                          <PopUpInfo
                            key={index}
                            tableRow={tableRow}
                            setShowImage={setShowImage}
                            setImageArraySrc={setImageArraySrc}
                          />
                        </Popup>
                      </CircleMarker>
                    ))
                    : ""}
                  <CreateMap map={map} bounds={bounds} />
                </MapContainer>
              ) : (
                ""
              )}
            </div>
          </Container>
        )}
      </>
    );
  }
  return null;
};

export default Map;
