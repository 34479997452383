import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ReactTooltip from "react-tooltip";
import { PieChart } from "react-minimal-pie-chart";

import DownloadButton from "../buttons/download-button";
import { userRoles } from "../utilities/utilities"

const Card = styled.article`
  button#sectionHeader {
    color: ${(props) => props.theme.colors.primaryColors.white};
    padding: 0.75rem 0;
    font-size: 0.75rem;
    text-align: center;
    cursor: pointer;
    &:not(:last-child) {
      border-right: 1px solid
        ${(props) => props.theme.variables.cards.benchmarks.border};
    }
    &:hover {
      background: ${(props) => props.theme.variables.charts.hoverTabBackground};
    }
  }
  .tabs {
    transition: all 250ms;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border: 1px solid
      ${(props) => props.theme.variables.cards.benchmarks.border};
    background: ${(props) => props.theme.variables.charts.tabBackground};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .active {
    background: ${(props) => props.theme.colors.primaryColors.blue};
    font-weight: 600;
    &:hover {
      background: ${(props) =>
        props.theme.colors.primaryColors.blue} !important;
    }
  }
  .content-container {
    padding: 1rem;
    background: ${(props) => props.theme.variables.charts.background};
    border-left: 1px solid
      ${(props) => props.theme.variables.cards.benchmarks.border};
    border-right: 1px solid
      ${(props) => props.theme.variables.cards.benchmarks.border};
    border-bottom: 1px solid
      ${(props) => props.theme.variables.cards.benchmarks.border};
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    transition: all 250ms;
  }
  &:hover {
    .tabs {
      border-left: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
      border-right: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
      border-top: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
    }
    .content-container {
      border-left: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
      border-right: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
      border-bottom: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
    }
  }
`;

const Card2 = styled.article`
  button#sectionHeader {
    color: ${(props) => props.theme.colors.primaryColors.white};
    padding: 7.5px 0;
    font-size: 0.75rem;
    text-align: center;
    cursor: pointer;
    height: 38px;
    width: 139px;
    &:not(:last-child) {
      border-right: 1px solid
        ${(props) => props.theme.variables.cards.benchmarks.border};
    }
    &:hover {
      background: ${(props) => props.theme.variables.charts.hoverTabBackground};
    }
  }
  .tabs {
    transition: all 250ms;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border: 1px solid
      ${(props) => props.theme.variables.cards.benchmarks.border};
    background: ${(props) => props.theme.variables.charts.tabBackground};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .active {
    background: ${(props) => props.theme.colors.primaryColors.blue};
    font-weight: 600;
    &:hover {
      background: ${(props) =>
        props.theme.colors.primaryColors.blue} !important;
    }
  }
  .content-container {
    padding: 1rem;
    background: ${(props) => props.theme.variables.charts.background};
    border-left: 1px solid
      ${(props) => props.theme.variables.cards.benchmarks.border};
    border-right: 1px solid
      ${(props) => props.theme.variables.cards.benchmarks.border};
    border-bottom: 1px solid
      ${(props) => props.theme.variables.cards.benchmarks.border};
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    transition: all 250ms;
  }
  &:hover {
    .tabs {
      border-left: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
      border-right: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
      border-top: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
    }
    .content-container {
      border-left: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
      border-right: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
      border-bottom: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
    }
  }
`;

const Content = styled.div`
  height: 275px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;
  ul {
    padding: 0 0 0 1rem;
  }
  li {
    font-size: 0.9375rem;
    margin: 0.25rem 0;
  }
  .download-button {
    position: absolute;
    inset: 0 0 auto auto;
    z-index: 5;
  }
  .color-block {
    height: 10px;
    width: 10px;
    margin: 0 10px 0 0;
    display: inline-block;
  }
  .blue {
    background: ${(props) => props.theme.colors.chartColors.recover.lightBlue};
  }
  .light-blue {
    background: ${(props) => props.theme.colors.chartColors.recover.paleBlue};
  }
  .pale-green {
    background: ${(props) => props.theme.colors.chartColors.recover.paleGreen};
  }
  .purple {
    background: ${(props) => props.theme.colors.chartColors.recover.purple};
  }
  .pink {
    background: ${(props) => props.theme.colors.chartColors.recover.pink};
  }
  .gray {
    background: ${(props) => props.theme.colors.chartColors.recover.gray};
  }
  #total-section,
  #main-section,
  #service-section,
  #customer-side-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    height: 100%;
    ul {
      order: 2;
    }
    #total-section,
    #main-section,
    #service-section,
    #customer-side-section {
      grid-template-columns: 1fr;
    }
  }
`;

const Content2 = styled.div`
  height: 275px;
  width: 525px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;
  ul {
    padding: 0 0 0 1rem;
  }
  li {
    font-size: 0.9375rem;
    margin: 0.25rem 0;
  }
  .download-button {
    position: absolute;
    inset: 0 0 auto auto;
    z-index: 5;
  }
  .color-block {
    height: 10px;
    width: 10px;
    margin: 0 10px 0 0;
    display: inline-block;
  }
  .blue {
    background: ${(props) => props.theme.colors.chartColors.recover.lightBlue};
  }
  .light-blue {
    background: ${(props) => props.theme.colors.chartColors.recover.paleBlue};
  }
  .pale-green {
    background: ${(props) => props.theme.colors.chartColors.recover.paleGreen};
  }
  .purple {
    background: ${(props) => props.theme.colors.chartColors.recover.purple};
  }
  .pink {
    background: ${(props) => props.theme.colors.chartColors.recover.pink};
  }
  .gray {
    background: ${(props) => props.theme.colors.chartColors.recover.gray};
  }
  #total-section,
  #main-section,
  #service-section,
  #customer-side-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
`;

const Diagram = styled.div`
  .pie-chart {
    padding: 1rem;
    max-width: 275px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    order: 1;
    display: flex;
    justify-content: center;
    .pie-chart {
      width: 50%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    .pie-chart {
      width: 75%;
    }
  }
`;

const Diagram2 = styled.div`
  .pie-chart {
    padding: 1rem;
    max-width: 275px;
  }
`;

const Leak = (props) => {
  const {
    projectName,
    role,
    pdf,
    leakTypeTotal,
    leakTypeMain,
    leakTypeService,
    leakTypeCustomerSide,
  } = props;

  // Translation component
  const { t } = useTranslation();

  // The properties for dom element
  const htmlClass = {
    id: "leakType",
    rotation: 0,
    lMargin: 0,
    tMargin: 15,
    width: 480,
    height: 600,
  };

  const graphName = `LeakType`;

  const options = {
    options: [
      {
        id: 0,
        title: t("download.jpg"),
      },
      {
        id: 1,
        title: t("download.png"),
      },
    ],
    menu_display_direction: "left",
  };

  // Toggle display of active tab
  const [tabSections, setTabSections] = useState({
    total: "show-content",
    totalSection: "active",
    main: "hide-content",
    mainSection: "",
    service: "hide-content",
    serviceSection: "",
    customerSide: "hide-content",
    customerSideSection: "",
  });
  const openTab = (tab) => {
    if (tab === "total") {
      setTabSections({
        total: "show-content",
        totalSection: "active",
        main: "hide-content",
        mainSection: "",
        service: "hide-content",
        serviceSection: "",
        customerSide: "hide-content",
        customerSideSection: "",
      });
    } else if (tab === "main") {
      setTabSections({
        total: "hide-content",
        totalSection: "",
        main: "show-content",
        mainSection: "active",
        service: "hide-content",
        serviceSection: "",
        customerSide: "hide-content",
        customerSideSection: "",
      });
    } else if (tab === "service") {
      setTabSections({
        total: "hide-content",
        totalSection: "",
        main: "hide-content",
        mainSection: "",
        service: "show-content",
        serviceSection: "active",
        customerSide: "hide-content",
        customerSideSection: "",
      });
    } else if (tab === "customerSide") {
      setTabSections({
        total: "hide-content",
        totalSection: "",
        main: "hide-content",
        mainSection: "",
        service: "hide-content",
        serviceSection: "",
        customerSide: "show-content",
        customerSideSection: "active",
      });
    }
  };

  // Set tooltip for each portion of pie chart
  const makeTooltipContent = (tooltip) => {
    return (
      <>
        {role != userRoles.CLIENT_VIEWER && (
          <p className="tooltip-description">
            <span className="mid-weight">{tooltip.value}</span> {tooltip.title}
          </p>
        )}
      </>
    );
  };

  // Create arrays for all data entries
  const [totalHovered, setTotalHovered] = useState(0);
  const [mainHovered, setMainHovered] = useState(0);
  const [serviceHovered, setServiceHovered] = useState(0);
  const [customerSideHovered, setCustomerSideHovered] = useState(0);

  const totalSection = [
    {
      title: t("leak-type.total.customer-side"),
      value: leakTypeTotal.leakTypeCustomerSide,
      color: "#3355FF",
    },
    {
      title: t("leak-type.total.main"),
      value: leakTypeTotal.leakTypeMain,
      color: "#00B1FF",
    },
    {
      title: t("leak-type.total.service"),
      value: leakTypeTotal.leakTypeService,
      color: "#04DBA8",
    },
    {
      title: t("leak-type.total.wo"),
      value: leakTypeTotal.leakTypeWo,
      color: "#EC00FF",
    },
    {
      title: t("leak-type.total.other"),
      value: leakTypeTotal.leakTypeOther,
      color: "#9D00FF",
    },
  ];

  const totalSectionWithoutWo = [
    {
      title: t("leak-type.total.customer-side"),
      value: leakTypeTotal.leakTypeCustomerSide,
      color: "#3355FF",
    },
    {
      title: t("leak-type.total.main"),
      value: leakTypeTotal.leakTypeMain,
      color: "#00B1FF",
    },
    {
      title: t("leak-type.total.service"),
      value: leakTypeTotal.leakTypeService,
      color: "#04DBA8",
    },
    {
      title: t("leak-type.total.other"),
      value: leakTypeTotal.leakTypeOther,
      color: "#9D00FF",
    },
  ];

  const mainSection = [
    {
      title: t("leak-type.main.connection-fittings"),
      value: leakTypeMain.leakTypeConnectionsFittings,
      color: "#696969",
    },
    {
      title: t("leak-type.main.fire-hydrant"),
      value: leakTypeMain.leakTypeFireHydrant,
      color: "#9D00FF",
    },
    {
      title: t("leak-type.main.pipe"),
      value: leakTypeMain.leakTypePipe,
      color: "#3355FF",
    },
    {
      title: t("leak-type.main.valve"),
      value: leakTypeMain.leakTypeValve,
      color: "#04DBA8",
    },
  ];

  const serviceSection = [
    {
      title: t("leak-type.service.connection-fittings"),
      value: leakTypeService.leakTypeConnectionsFittings,
      color: "#696969",
    },
    {
      title: t("leak-type.service.curb-stop-stop-tap"),
      value: leakTypeService.leakTypeCurbStop,
      color: "#9D00FF",
    },
    {
      title: t("leak-type.service.pipe"),
      value: leakTypeService.leakTypePipe,
      color: "#3355FF",
    },
    {
      title: t("leak-type.service.meter"),
      value: leakTypeService.leakTypeMeter,
      color: "#04DBA8",
    },
  ];
  const customerSideSection = [
    {
      title: t("leak-type.customer-side.utility-loss"),
      value: leakTypeCustomerSide.leakTypeUtilityLoss,
      color: "#696969",
    },
    {
      title: t("leak-type.customer-side.pipe"),
      value: leakTypeCustomerSide.leakTypePipe,
      color: "#9D00FF",
    },
    {
      title: t("leak-type.customer-side.meter"),
      value: leakTypeCustomerSide.leakTypeMeter,
      color: "#3355FF",
    },
    {
      title: t("leak-type.customer-side.parks-and-recreation"),
      value: leakTypeCustomerSide.leakTypeParksRecreation,
      color: "#04DBA8",
    },
  ];

  return (
    <>
      {
        role != userRoles.CLIENT_VIEWER && pdf == false && (
          <Card>
            <div className="tabs">
              <button
                id="sectionHeader"
                onClick={() => openTab("total")}
                className={tabSections.totalSection}
              >
                {t("leak-type.total")}
              </button>
              <button
                id="sectionHeader"
                onClick={() => openTab("main")}
                className={tabSections.mainSection}
              >
                {t("leak-type.main")}
              </button>
              <button
                id="sectionHeader"
                onClick={() => openTab("service")}
                className={tabSections.serviceSection}
              >
                {t("leak-type.service")}
              </button>
              <button
                id="sectionHeader"
                onClick={() => openTab("customerSide")}
                className={tabSections.customerSideSection}
              >
                {t("leak-type.customer-side")}
              </button>
            </div>
            <div className="content-container">
              <Content>
                {/* Total Pie Chart */}
                <div
                  id="total-section"
                  className={tabSections.total}
                  data-tip
                  data-for="total-chart"
                >
                  <div className="download-button">
                    <DownloadButton
                      downloadOptions={options}
                      htmlClass={htmlClass}
                      projectName={projectName}
                      type={`${graphName}_Total`}
                    />
                  </div>
                  <ul>
                    <li>
                      <span className="color-block blue" />
                      {t("leak-type.total.customer-side")}
                    </li>
                    <li>
                      <span className="color-block light-blue" />
                      {t("leak-type.total.main")}
                    </li>
                    <li>
                      <span className="color-block pale-green" />
                      {t("leak-type.total.service")}
                    </li>
                    {(
                      <li>
                        <span className="color-block pink" />
                        {t("leak-type.total.wo")}
                      </li>
                    )}
                    <li>
                      <span className="color-block purple" />
                      {t("leak-type.total.other")}
                    </li>
                  </ul>
                  <Diagram>
                    <div className="pie-chart">
                      <PieChart
                        data={totalSection}
                        lineWidth={30}
                        paddingAngle={0}
                        startAngle={-90}
                        onMouseOver={(_, index) => {
                          setTotalHovered(index);
                        }}
                        onMouseOut={() => {
                          setTotalHovered(null);
                        }}
                      />
                    </div>
                  </Diagram>
                </div>
                <ReactTooltip
                  id="total-chart"
                  effect="float"
                  data-multiline={true}
                  backgroundColor={"#505050"}
                  getContent={() =>
                    typeof totalHovered === "number"
                      ? makeTooltipContent(totalSection[totalHovered])
                      : null
                  }
                />
                {/* Main Pie Chart */}
                <div
                  id="main-section"
                  className={tabSections.main}
                  data-tip
                  data-for="main-chart"
                >
                  <div className="download-button">
                    <DownloadButton
                      downloadOptions={options}
                      htmlClass={htmlClass}
                      projectName={projectName}
                      type={`${graphName}_Main`}
                    />
                  </div>
                  <ul>
                    <li>
                      <span className="color-block gray" />
                      {t("leak-type.main.connection-fittings")}
                    </li>
                    <li>
                      <span className="color-block purple" />
                      {t("leak-type.main.fire-hydrant")}
                    </li>
                    <li>
                      <span className="color-block blue" />
                      {t("leak-type.main.pipe")}
                    </li>
                    <li>
                      <span className="color-block pale-green" />
                      {t("leak-type.main.valve")}
                    </li>
                  </ul>
                  <Diagram>
                    <div className="pie-chart">
                      <PieChart
                        data={mainSection}
                        lineWidth={30}
                        paddingAngle={0}
                        startAngle={-90}
                        onMouseOver={(_, index) => {
                          setMainHovered(index);
                        }}
                        onMouseOut={() => {
                          setMainHovered(null);
                        }}
                      />
                    </div>
                  </Diagram>
                </div>
                <ReactTooltip
                  id="main-chart"
                  effect="float"
                  data-multiline={true}
                  backgroundColor={"#505050"}
                  getContent={() =>
                    typeof mainHovered === "number"
                      ? makeTooltipContent(mainSection[mainHovered])
                      : null
                  }
                />
                {/* Service Pie Chart */}
                <div
                  id="service-section"
                  className={tabSections.service}
                  data-tip
                  data-for="service-chart"
                >
                  <div className="download-button">
                    <DownloadButton
                      downloadOptions={options}
                      htmlClass={htmlClass}
                      projectName={projectName}
                      type={`${graphName}_Service`}
                    />
                  </div>
                  <ul>
                    <li>
                      <span className="color-block gray" />
                      {t("leak-type.service.connection-fittings")}
                    </li>
                    <li>
                      <span className="color-block purple" />
                      {t("leak-type.service.curb-stop-stop-tap")}
                    </li>
                    <li>
                      <span className="color-block blue" />
                      {t("leak-type.service.pipe")}
                    </li>
                    <li>
                      <span className="color-block pale-green" />
                      {t("leak-type.service.meter")}
                    </li>
                  </ul>
                  <Diagram>
                    <div className="pie-chart">
                      <PieChart
                        data={serviceSection}
                        lineWidth={30}
                        paddingAngle={0}
                        startAngle={-90}
                        onMouseOver={(_, index) => {
                          setServiceHovered(index);
                        }}
                        onMouseOut={() => {
                          setServiceHovered(null);
                        }}
                      />
                    </div>
                  </Diagram>
                </div>
                <ReactTooltip
                  id="service-chart"
                  effect="float"
                  data-multiline={true}
                  backgroundColor={"#505050"}
                  getContent={() =>
                    typeof serviceHovered === "number"
                      ? makeTooltipContent(serviceSection[serviceHovered])
                      : null
                  }
                />
                {/* Customer Side Pie Chart */}
                <div
                  id="customer-side-section"
                  className={tabSections.customerSide}
                  data-tip
                  data-for="customer-side-chart"
                >
                  <div className="download-button">
                    <DownloadButton
                      downloadOptions={options}
                      htmlClass={htmlClass}
                      projectName={projectName}
                      type={`${graphName}_CustomerSide`}
                    />
                  </div>
                  <ul>
                    <li>
                      <span className="color-block gray" />
                      {t("leak-type.customer-side.utility-loss")}
                    </li>
                    <li>
                      <span className="color-block purple" />
                      {t("leak-type.customer-side.pipe")}
                    </li>
                    <li>
                      <span className="color-block blue" />
                      {t("leak-type.customer-side.meter")}
                    </li>
                    <li>
                      <span className="color-block pale-green" />
                      {t("leak-type.customer-side.parks-and-recreation")}
                    </li>
                  </ul>
                  <Diagram>
                    <div className="pie-chart">
                      <PieChart
                        data={customerSideSection}
                        lineWidth={30}
                        paddingAngle={0}
                        startAngle={-90}
                        onMouseOver={(_, index) => {
                          setCustomerSideHovered(index);
                        }}
                        onMouseOut={() => {
                          setCustomerSideHovered(null);
                        }}
                      />
                    </div>
                  </Diagram>
                </div>
                <ReactTooltip
                  id="customer-side-chart"
                  effect="float"
                  data-multiline={true}
                  backgroundColor={"#505050"}
                  getContent={() =>
                    typeof customerSideHovered === "number"
                      ? makeTooltipContent(
                          customerSideSection[customerSideHovered]
                        )
                      : null
                  }
                />
              </Content>
            </div>
          </Card>
        )
      }
      {
        role != userRoles.CLIENT_VIEWER && pdf && (
          <Card2 name="leakType">
            <div className="tabs">
              <button
                id="sectionHeader"
                onClick={() => openTab("total")}
                className={tabSections.totalSection}
              >
                {t("leak-type.total")}
              </button>
              <button
                id="sectionHeader"
                onClick={() => openTab("main")}
                className={tabSections.mainSection}
              >
                {t("leak-type.main")}
              </button>
              <button
                id="sectionHeader"
                onClick={() => openTab("service")}
                className={tabSections.serviceSection}
              >
                {t("leak-type.service")}
              </button>
              <button
                id="sectionHeader"
                onClick={() => openTab("customerSide")}
                className={tabSections.customerSideSection}
              >
                {t("leak-type.customer-side")}
              </button>
            </div>
            <div className="content-container">
              <Content2>
                {/* Total Pie Chart */}
                <div
                  id="total-section"
                  className={tabSections.total}
                  data-tip
                  data-for="total-chart"
                >
                  <div className="download-button">
                    <DownloadButton
                      downloadOptions={options}
                      htmlClass={htmlClass}
                      projectName={projectName}
                      type={`${graphName}_Total`}
                    />
                  </div>
                  <ul>
                    <li>
                      <span className="color-block blue" />
                      {t("leak-type.total.customer-side")}
                    </li>
                    <li>
                      <span className="color-block light-blue" />
                      {t("leak-type.total.main")}
                    </li>
                    <li>
                      <span className="color-block pale-green" />
                      {t("leak-type.total.service")}
                    </li>
                    {(
                      <li>
                        <span className="color-block pink" />
                        {t("leak-type.total.wo")}
                      </li>
                    )}
                    <li>
                      <span className="color-block purple" />
                      {t("leak-type.total.other")}
                    </li>
                  </ul>
                  <Diagram2>
                    <div className="pie-chart">
                      <PieChart
                        data={totalSection}
                        lineWidth={30}
                        paddingAngle={0}
                        startAngle={-90}
                        onMouseOver={(_, index) => {
                          setTotalHovered(index);
                        }}
                        onMouseOut={() => {
                          setTotalHovered(null);
                        }}
                      />
                    </div>
                  </Diagram2>
                </div>
                <ReactTooltip
                  id="total-chart"
                  effect="float"
                  data-multiline={true}
                  backgroundColor={"#505050"}
                  getContent={() =>
                    typeof totalHovered === "number"
                      ? makeTooltipContent(totalSection[totalHovered])
                      : null
                  }
                />
                {/* Main Pie Chart */}
                <div
                  id="main-section"
                  className={tabSections.main}
                  data-tip
                  data-for="main-chart"
                >
                  <div className="download-button">
                    <DownloadButton
                      downloadOptions={options}
                      htmlClass={htmlClass}
                      projectName={projectName}
                      type={`${graphName}_Main`}
                    />
                  </div>
                  <ul>
                    <li>
                      <span className="color-block gray" />
                      {t("leak-type.main.connection-fittings")}
                    </li>
                    <li>
                      <span className="color-block purple" />
                      {t("leak-type.main.fire-hydrant")}
                    </li>
                    <li>
                      <span className="color-block blue" />
                      {t("leak-type.main.pipe")}
                    </li>
                    <li>
                      <span className="color-block pale-green" />
                      {t("leak-type.main.valve")}
                    </li>
                  </ul>
                  <Diagram2>
                    <div className="pie-chart">
                      <PieChart
                        data={mainSection}
                        lineWidth={30}
                        paddingAngle={0}
                        startAngle={-90}
                        onMouseOver={(_, index) => {
                          setMainHovered(index);
                        }}
                        onMouseOut={() => {
                          setMainHovered(null);
                        }}
                      />
                    </div>
                  </Diagram2>
                </div>
                <ReactTooltip
                  id="main-chart"
                  effect="float"
                  data-multiline={true}
                  backgroundColor={"#505050"}
                  getContent={() =>
                    typeof mainHovered === "number"
                      ? makeTooltipContent(mainSection[mainHovered])
                      : null
                  }
                />
                {/* Service Pie Chart */}
                <div
                  id="service-section"
                  className={tabSections.service}
                  data-tip
                  data-for="service-chart"
                >
                  <div className="download-button">
                    <DownloadButton
                      downloadOptions={options}
                      htmlClass={htmlClass}
                      projectName={projectName}
                      type={`${graphName}_Service`}
                    />
                  </div>
                  <ul>
                    <li>
                      <span className="color-block gray" />
                      {t("leak-type.service.connection-fittings")}
                    </li>
                    <li>
                      <span className="color-block purple" />
                      {t("leak-type.service.curb-stop-stop-tap")}
                    </li>
                    <li>
                      <span className="color-block blue" />
                      {t("leak-type.service.pipe")}
                    </li>
                    <li>
                      <span className="color-block pale-green" />
                      {t("leak-type.service.meter")}
                    </li>
                  </ul>
                  <Diagram2>
                    <div className="pie-chart">
                      <PieChart
                        data={serviceSection}
                        lineWidth={30}
                        paddingAngle={0}
                        startAngle={-90}
                        onMouseOver={(_, index) => {
                          setServiceHovered(index);
                        }}
                        onMouseOut={() => {
                          setServiceHovered(null);
                        }}
                      />
                    </div>
                  </Diagram2>
                </div>
                <ReactTooltip
                  id="service-chart"
                  effect="float"
                  data-multiline={true}
                  backgroundColor={"#505050"}
                  getContent={() =>
                    typeof serviceHovered === "number"
                      ? makeTooltipContent(serviceSection[serviceHovered])
                      : null
                  }
                />
                {/* Customer Side Pie Chart */}
                <div
                  id="customer-side-section"
                  className={tabSections.customerSide}
                  data-tip
                  data-for="customer-side-chart"
                >
                  <div className="download-button">
                    <DownloadButton
                      downloadOptions={options}
                      htmlClass={htmlClass}
                      projectName={projectName}
                      type={`${graphName}_CustomerSide`}
                    />
                  </div>
                  <ul>
                    <li>
                      <span className="color-block gray" />
                      {t("leak-type.customer-side.utility-loss")}
                    </li>
                    <li>
                      <span className="color-block purple" />
                      {t("leak-type.customer-side.pipe")}
                    </li>
                    <li>
                      <span className="color-block blue" />
                      {t("leak-type.customer-side.meter")}
                    </li>
                    <li>
                      <span className="color-block pale-green" />
                      {t("leak-type.customer-side.parks-and-recreation")}
                    </li>
                  </ul>
                  <Diagram2>
                    <div className="pie-chart">
                      <PieChart
                        data={customerSideSection}
                        lineWidth={30}
                        paddingAngle={0}
                        startAngle={-90}
                        onMouseOver={(_, index) => {
                          setCustomerSideHovered(index);
                        }}
                        onMouseOut={() => {
                          setCustomerSideHovered(null);
                        }}
                      />
                    </div>
                  </Diagram2>
                </div>
                <ReactTooltip
                  id="customer-side-chart"
                  effect="float"
                  data-multiline={true}
                  backgroundColor={"#505050"}
                  getContent={() =>
                    typeof customerSideHovered === "number"
                      ? makeTooltipContent(
                          customerSideSection[customerSideHovered]
                        )
                      : null
                  }
                />
              </Content2>
            </div>
          </Card2>
        )
      }
    </>
  );
};

export default Leak;
