import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import AssetsIcon from "../../images/icons/assets.svg";
import AssetsDisabledIcon from "../../images/icons/assets-disabled.svg";
import ChainIcon from "../../images/icons/chain.svg";
import ChainDisabledIcon from "../../images/icons/chain-disabled.svg";
import ExportIcon from "../../images/icons/export.svg";
import ExportDropdown from "../../components/layouts/export-dropdown";
import ReactModal from "react-modal";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import EditIcon from "../../images/icons/edit.svg";
import CloseIcon from "../../images/icons/close.svg";
import { SaveButton } from "../../components/buttons/buttons";
import DropdownIcon from "../../images/static/dropdown.png";
import { userRoles } from "../../components/utilities/utilities"

const style = {
  disabled_button: {
    cursor: "default",
    color: "#999999",
  },
};

const Radio = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  margin: 0 5px;
  input {
    opacity: 0;
    height: 0;
    width: 0;
  }
  .slider {
    position: absolute;
    border-radius: 1rem;
    cursor: pointer;
    inset: 0;
    background: none;
    transition: all 500ms;
    border: 1px solid
      ${(props) => props.theme.variables.buttons.workOrder.border};
    &:before {
      position: absolute;
      content: "";
      border-radius: 50%;
      height: 13px;
      width: 13px;
      inset: 0;
      background: ${(props) => props.theme.variables.buttons.workOrder.color};
      transition: all 500ms;
    }
  }
  input:checked + .slider {
    border: 1px solid
      ${(props) => props.theme.variables.buttons.workOrder.activeColor};
    background: ${(props) =>
      props.theme.variables.buttons.workOrder.activeBackground};
  }
  input:focus + .slider {
    box-shadow: 0 0 1px
      ${(props) => props.theme.variables.buttons.workOrder.activeColor};
  }
  input:checked + .slider:before {
    transform: translateX(15px);
    background: ${(props) =>
      props.theme.variables.buttons.workOrder.activeColor};
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    width: 52px;
    height: 27px;
    .slider {
      &:before {
        height: 25px;
        width: 25px;
      }
    }
    input:checked + .slider:before {
      transform: translateX(25px);
    }
  }
`;

const Container = styled.section`
  padding: 0.75rem 3rem;
  background: ${(props) => props.theme.variables.cards.dashboard.background};
  border-left: 1px solid ${(props) => props.theme.colors.primaryColors.black};
  border-top: 1px solid ${(props) => props.theme.colors.primaryColors.black};
  border-bottom: 1px solid
    ${(props) => props.theme.colors.uiPalette.dark.highGray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    display: flex;
    gap: 1.5rem;
  }
  .asset-link {
    font-size: 0.8125rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    color: white;
    .asset-icon {
      margin: 0 0.3rem 0 0;
    }
    &:hover {
      path {
        transition: all 250ms;
        fill: ${(props) => props.theme.colors.secondaryColors.orange};
      }
    }
  }
  .work-order-column {
    color: ${(props) => props.theme.variables.color};
    text-transform: uppercase;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    p {
      font-size: 0.8125rem;
      height: 20px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    padding: 0.75rem 1.5rem;
    ul {
      display: none;
    }
    .work-order-column {
      justify-content: space-between;
      width: 100%;
    }
  }
`;

const Recover = styled.section`
  .edit-button {
    font-size: 0.8125rem;
    font-weight: 500;
    border: none;
    background: none;
    color: ${(props) => props.theme.variables.color};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    .icon {
      margin: 0 0.5rem 0;
    }
    path {
      transition: all 250ms;
    }
    &:hover {
      color: ${(props) => props.theme.colors.secondaryColors.orange};
      path {
        fill: ${(props) => props.theme.colors.secondaryColors.orange};
      }
    }
  }
`;

const Modal = styled.div`
  background: ${(props) => props.theme.variables.cards.dashboard.background};
  border: 1px solid ${(props) => props.theme.variables.cards.dashboard.border};
  color: ${(props) => props.theme.variables.color};
  border-radius: 3px;
  padding: 2.5rem 3rem;
  button.close-modal-button {
    position: absolute;
    inset: 1rem 1rem auto auto;
    background: none;
    border: none;
    line {
      transition: all 250ms;
    }
    &:hover {
      line {
        stroke: ${(props) => props.theme.colors.secondaryColors.orange};
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    width: 350px;
    padding: 2rem;
  }
`;

const PerformanceForm = styled.form`
  input[type="number"],
  input[type="text"],
  select,
  textarea {
    color: ${(props) => props.theme.variables.color};
    text-transform: uppercase;
    font-size: 0.9375rem;
    background: none;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.variables.form.border};
    padding: 0.3rem;
    &:focus {
      outline: none !important;
      border-bottom: 1px solid
        ${(props) => props.theme.colors.secondaryColors.green};
      color: ${(props) => props.theme.colors.secondaryColors.green};
    }
  }
  select {
    border: 1px solid ${(props) => props.theme.variables.form.border};
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url(${DropdownIcon})
      ${(props) => props.theme.variables.form.background} 95% / 10px no-repeat;
    padding: 0.3rem 2rem 0.3rem 0.7rem;
    &:focus {
      outline: none !important;
      border: 1px solid ${(props) => props.theme.colors.secondaryColors.green};
      color: ${(props) => props.theme.colors.secondaryColors.green};
    }
  }
  .input-section {
    margin: 1.75rem 0;
  }
  .title-row {
    margin: 0.25rem 0;
    p {
      font-size: 0.9375rem;
    }
  }
  .water-input-row {
    display: grid;
    grid-template-columns: 20% 45% 25% 10%;
    grid-gap: 1rem;
  }
  .power-input-row {
    display: grid;
    grid-template-columns: 20% 20% 25% 35%;
    grid-gap: 1rem;
    p {
      line-height: 1;
      text-transform: uppercase;
      padding: 0.3rem;
    }
  }
  .treatment-input-row {
    display: grid;
    grid-template-columns: 20% 30% 25% 25%;
    grid-gap: 1rem;
    p {
      font-size: 0.5625rem;
      align-self: center;
      justify-self: start;
    }
  }
  .greenhouse-input-row {
    display: grid;
    grid-template-columns: 20% 20% 25% 35%;
    grid-gap: 1rem;
    p {
      font-size: 0.5625rem;
      align-self: center;
      justify-self: start;
    }
  }
  .comment-input {
    width: 100%;
    height: 50px;
    text-transform: none;
    margin: 0;
  }
  button.reset-button {
    font-size: 0.5625rem;
    background: none;
    border: none;
    text-decoration: underline;
    align-self: center;
    justify-self: start;
    color: ${(props) => props.theme.variables.color};
    &:hover {
      color: ${(props) => props.theme.colors.secondaryColors.orange};
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    .mobile-sizing {
      grid-template-columns: 25% 75%;
    }
    .input-section {
      margin: 1rem 0;
    }
    .comment-input {
      margin: 0 0 1rem 0;
    }
    button.reset-button {
      grid-column: 1 / 4;
    }
  }
`;

const Assets = (props) => {
  // Translation component
  const { t } = useTranslation();
  const { total_leaks_with_wo } = props.assetData

  // Replace with your preferred function
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const waterCostValue = watch("water_cost");
  const powerCostValue = watch("power_cost");
  const treatmentCostValue = watch("treatment_cost");
  const greenhouseCostValue = watch("greenhouse_cost");
  const commentsAndRemarks = watch("comments_and_remarks");

  const [lastBaseValues, setLastBaseValues] = useState({
    waterCostSaved: props.baseValues.water_cost,
    waterMeasurementSaved: "liter",
    powerCostSaved: props.baseValues.power_cost,
    treatmentCostSaved: props.baseValues.treatment_cost,
    treatmentMeasurementSaved: "liter",
    greenhouseCostSaved: props.baseValues.greenhouse_gas_emissions,
    greenhouseMeasurementSaved: "kilogram",
    commentsSaved: props.baseValues.comments_and_remarks,
  });

  const dispatch = useDispatch();
  const { toggleWmsModal } = props;

  const isMetricOrUs = useSelector((state) => state.general.isMetricOrUs);
  const loginValues = useSelector((state) => state.general.loginValues);

  const defaultMetricType = {
    waterCost: isMetricOrUs === "Standard Metric" ? "liter" : "gallon",
    treatmentCost: isMetricOrUs === "Standard Metric" ? "liter" : "gallon",
    gasEmission: isMetricOrUs === "Standard Metric" ? "kilogram" : "pound",
  };

  const convertToInputUnit = (value, unit) => {
    if (unit === "gallon") {
      return value / 3.785;
    }
    if (unit === "pound") {
      return value / 2.205;
    }
    return value;
  };

  const onSubmit = (data) => {
    if (modalIsOpen) {
      if (
        data.water_cost === "0" ||
        data.power_cost === "0" ||
        // data.treatment_cost == "0" ||
        data.greenhouse_cost === "0"
      ) {
        alert("can not set 0 values");
        return;
      }

      const waterCostPostValue = convertToInputUnit(
        data.water_cost,
        data.water_measurement
      );
      const treatmentCostPostValue = convertToInputUnit(
        data.treatment_cost,
        data.treatment_measurement
      );
      const greenhouseCostPostValue = convertToInputUnit(
        data.greenhouse_cost,
        data.greenhouse_measurement
      );
      const powerCostPostValue = data.power_cost;
      const commentsAndRemarksPostValue = data.comments;
      try {
        axios
          .post(`/base-values`, {
            data: {
              sub_project: props.projectData.sub_project,
              water_cost: waterCostPostValue,
              treatment_cost: treatmentCostPostValue,
              greenhouse_gas_emissions: greenhouseCostPostValue,
              power_cost: powerCostPostValue,
              comments_and_remarks: commentsAndRemarksPostValue,
            },
            userId: loginValues.userId,
          })
          .then(() => {
            const newData = { ...props.filteredData };
            newData.baseValues.comments_and_remarks =
              commentsAndRemarksPostValue;
            newData.baseValues.greenhouse_gas_emissions =
              greenhouseCostPostValue;
            newData.baseValues.power_cost = powerCostPostValue;
            newData.baseValues.treatment_cost = treatmentCostPostValue;
            newData.baseValues.water_cost = waterCostPostValue;

            props.setFilteredData(newData);

            props.setPerformanceCardValues(
              {
                comments: commentsAndRemarksPostValue,
                greenhouse_cost: greenhouseCostPostValue,
                power_cost: powerCostPostValue,
                treatment_cost: treatmentCostPostValue,
                water_cost: waterCostPostValue,
              },
              {
                lastWaterCostValue: lastBaseValues.waterCostSaved,
                lastPowerCostValue: lastBaseValues.powerCostSaved,
                lastTreatmentCostValue: lastBaseValues.treatmentCostSaved,
                lastGreenhouseCostValue: lastBaseValues.greenhouseCostSaved,
              }
            );

            setLastBaseValues({
              waterCostSaved: data.water_cost,
              waterMeasurementSaved: data.water_measurement,
              powerCostSaved: data.power_cost,
              treatmentCostSaved: data.treatment_cost,
              treatmentMeasurementSaved: data.treatment_measurement,
              greenhouseCostSaved: data.greenhouse_cost,
              greenhouseMeasurementSaved: data.greenhouse_measurement,
              commentsSaved: data.comments,
            });
          });
      } catch (err) {
        console.log(err);
      }
      toggleModal();
    }
  };

  // Toggling of modal window

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => {
    if (props.role !== userRoles.CLIENT_VIEWER) {
      setModalIsOpen(!modalIsOpen);
    }

    setValue("water_cost", lastBaseValues.waterCostSaved);
    setValue("water_measurement", lastBaseValues.waterMeasurementSaved);
    setValue("power_cost", lastBaseValues.powerCostSaved);
    setValue("treatment_cost", lastBaseValues.treatmentCostSaved);
    setValue("treatment_measurement", lastBaseValues.treatmentMeasurementSaved);
    setValue("greenhouse_cost", lastBaseValues.greenhouseCostSaved);
    setValue(
      "greenhouse_measurement",
      lastBaseValues.greenhouseMeasurementSaved
    );
    setValue("comments", lastBaseValues.commentsSaved);
  };

  // Replace with your preferred function
  const resetToDefault = (e) => {
    e.preventDefault();

    if (e.target.name === "water_cost") {
      e.target.form[1].value === "gallon"
        ? setValue("water_cost", "0.00586675")
        : setValue("water_cost", "0.00155");
    } else if (e.target.name === "power_cost") {
      setValue("power_cost", "0.1426");
    } else if (e.target.name === "treatment_cost") {
      setValue("treatment_cost", "0");
    } else {
      e.target.form[9].value === "pound"
        ? setValue("greenhouse_cost", "0.94815")
        : setValue("greenhouse_cost", "0.71");
    }
  };

  const [dropdownOpen, setdropdownOpen] = useState(false)

  const customStyles = {
    content: {
      top: "28%",
      left: "47%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "auto",
      width: "auto",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  };

  const dropdownToggle = () => {
    dropdownOpen ? setdropdownOpen(false) : setdropdownOpen(true);
  };

  return (
    <div>
      <Container style={{ overflow: "hidden" }}>
        <ul style={{ overflow: "hidden" }}>
          {props.role !== userRoles.CLIENT_VIEWER && (
            <>
              <li>
                {props.projectData.gis_files !== "" && (
                  <a
                    href={props.projectData.gis_files}
                    target="_blank"
                    className="asset-link"
                  >
                    <AssetsIcon
                      className="asset-icon"
                      alt="Icon that represents downloading"
                    />{" "}
                    {t("gis-files")}
                  </a>
                )}
                {props.projectData.gis_files === "" && (
                  <span style={style.disabled_button}>
                    <AssetsDisabledIcon
                      className="asset-icon"
                      alt="Icon that represents downloading"
                    />{" "}
                    {t("gis-files")}
                  </span>
                )}
              </li>
              <li>
                {props.projectData.wms !== "" &&
                  props.projectData.wms !== null && (
                    <button onClick={toggleWmsModal} className="asset-link">
                      <ChainIcon
                        className="asset-icon"
                        alt="Icon of a chain link that represents links"
                      />
                      {t("wms")}
                    </button>
                  )}
                {props.projectData.wms === "" ||
                props.projectData.wms === null ? (
                  <span style={style.disabled_button}>
                    <ChainDisabledIcon
                      className="asset-icon"
                      alt="Icon of a chain link that represents links"
                    />{" "}
                    {t("wms")}
                  </span>
                ) : null}
              </li>
            </>
          )}

          <li>
            {props.projectData.leaksheets_dataform !== "" && (
              <a
                href={props.projectData.leaksheets_dataform}
                className="asset-link"
                target="_blank"
              >
                <AssetsIcon
                  className="asset-icon"
                  alt="Icon that represents downloading"
                />{" "}
                {t("leaksheets-and-dataform")}
              </a>
            )}
            {props.projectData.leaksheets_dataform === "" && (
              <span style={style.disabled_button}>
                <AssetsDisabledIcon
                  className="asset-icon"
                  alt="Icon that represents downloading"
                />{" "}
                {t("leaksheets-and-dataform")}
              </span>
            )}
          </li>
          {props.role !== userRoles.CLIENT_VIEWER && (
            <li>
              <button
                disabled={total_leaks_with_wo === 0 ? true : false}
                style={total_leaks_with_wo === 0 ? style.disabled_button : null}
                onClick={() => {
                  dropdownToggle();
                }}
                className="asset-link"
              >
                <ExportIcon
                  className="asset-icon"
                  alt="Icon that represents file exporting"
                />{" "}
                {t("export")}
              </button>
            </li>
          )}
        </ul>
        <ul style={{ diplay: "flex", alignItems: "center" }}>
          {props.role !== userRoles.CLIENT_VIEWER && (
            <>
              <li>
                {props.showEditInputs && (
                  <Recover>
                    <button className="edit-button" onClick={toggleModal}>
                      <EditIcon className="asset-icon" />{" "}
                      <span style={{ marginLeft: 3 }}>
                        {t("recover.edit-button")}
                      </span>
                    </button>
                  </Recover>
                )}
              </li>
              <li>
                <div className="work-order-column">
                  <p>{t("work-order")}</p>
                  <Radio aria-label="Work Order Toggle">
                    <input
                      type="checkbox"
                      onChange={props.woCheckHandler}
                      checked={!props.wo}
                    />
                    <span
                      className="slider"
                      role="button"
                      aria-label="Work Order Toggle"
                      tabIndex={0}
                    ></span>
                  </Radio>
                </div>
              </li>
            </>
          )}
        </ul>
      </Container>
      {dropdownOpen && (
        <ExportDropdown
          originalDataBeforeFilter={props.originalDataBeforeFilter}
          projectValues={props.assetData}
          setPhotosModalIsOpen={props.setPhotosModalIsOpen}
          projectName={props.projectName}
          images={
            document.getElementsByClassName(
              "performance-cards__ChartCard-fALLzN grUXKv"
            )[0]
          }
        />
      )}
      {/* Modal */}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        className={"modal-content"}
        overlayClassName={"modal-overlay"}
        ariaHideApp={false}
      >
        <Modal>
          <h2>{t("edit-button.modal.h2")}</h2>
          <button onClick={toggleModal} className="close-modal-button">
            <CloseIcon aria-label="button" />
          </button>
          <PerformanceForm onSubmit={handleSubmit(onSubmit)}>
            <div className="input-section">
              <div className="title-row">
                <p>{t("edit-button.modal.water-cost")}</p>
              </div>
              <div className="water-input-row mobile-sizing">
                <input
                  {...register("water_cost")}
                  value={waterCostValue}
                  type="number"
                ></input>
                <select
                  id="waterCostSelect"
                  {...register("water_measurement")}
                  defaultValue={defaultMetricType.waterCost}
                  onChange={(e) => {
                    e.target.value == "gallon"
                      ? setValue("water_cost", e.target.form[0].value * 3.785)
                      : setValue("water_cost", e.target.form[0].value / 3.785);
                  }}
                >
                  <option value="liter">
                    {t("edit-button.modal.water-cost.liter")}
                  </option>
                  <option value="gallon">
                    {t("edit-button.modal.water-cost.gallon")}
                  </option>
                </select>
                <button
                  name="water_cost"
                  className="reset-button"
                  onClick={resetToDefault}
                >
                  {t("edit-button.modal.water-cost.reset")}
                </button>
              </div>
            </div>
            <div className="input-section">
              <div className="title-row">
                <p>{t("edit-button.modal.power-cost")}</p>
              </div>
              <div className="power-input-row mobile-sizing">
                <input
                  {...register("power_cost")}
                  value={powerCostValue}
                  type="number"
                ></input>
                <p>{t("edit-button.modal.power-cost.kilowatt")}</p>
                <button
                  name="power_cost"
                  className="reset-button"
                  onClick={resetToDefault}
                >
                  {t("edit-button.modal.power-cost.reset")}
                </button>
              </div>
            </div>
            <div className="input-section">
              <div className="title-row">
                <p>{t("edit-button.modal.treatment-cost")}</p>
              </div>
              <div className="treatment-input-row mobile-sizing">
                <input
                  {...register("treatment_cost")}
                  value={treatmentCostValue}
                  type="number"
                ></input>
                <select
                  id="treatmentCostSelect"
                  {...register("treatment_measurement")}
                  defaultValue={defaultMetricType.treatmentCost}
                  onChange={(e) =>
                    e.target.value == "gallon"
                      ? setValue(
                          "treatment_cost",
                          e.target.form[5].value * 3.785
                        )
                      : setValue(
                          "treatment_cost",
                          e.target.form[5].value / 3.785
                        )
                  }
                >
                  <option value="liter">
                    {t("edit-button.modal.treatment-cost.liter")}
                  </option>
                  <option value="gallon">
                    {t("edit-button.modal.treatment-cost.gallon")}
                  </option>
                </select>
                <button
                  name="treatment_cost"
                  className="reset-button"
                  onClick={resetToDefault}
                >
                  {t("edit-button.modal.treatment-cost.reset")}
                </button>
              </div>
            </div>
            <div className="input-section">
              <div className="title-row">
                <p>{t("edit-button.modal.greenhouse-gas-emissions")}</p>
              </div>
              <div className="greenhouse-input-row mobile-sizing">
                <input
                  {...register("greenhouse_cost")}
                  value={greenhouseCostValue}
                  type="number"
                ></input>
                <select
                  id="greenhouseSelect"
                  {...register("greenhouse_measurement")}
                  defaultValue={defaultMetricType.gasEmission}
                  onChange={(e) =>
                    e.target.value == "pound"
                      ? setValue(
                          "greenhouse_cost",
                          e.target.form[8].value * 2.205
                        )
                      : setValue(
                          "greenhouse_cost",
                          e.target.form[8].value / 2.205
                        )
                  }
                >
                  <option value="kilogram">
                    {t("edit-button.modal.greenhouse-gas-emissions.kilogram")}
                  </option>
                  <option value="pound">
                    {t("edit-button.modal.greenhouse-gas-emissions.pound")}
                  </option>
                </select>
                <button
                  name="greenhouse_cost"
                  className="reset-button"
                  onClick={resetToDefault}
                >
                  {t("edit-button.modal.greenhouse-gas-emissions.reset")}
                </button>
              </div>
            </div>
            <div className="input-section">
              <div className="title-row">
                <p>{t("edit-button.modal.comments")}</p>
              </div>
              <textarea
                {...register("comments")}
                value={commentsAndRemarks}
                defaultValue={props.baseValues.comments_and_remarks}
                className="comment-input"
              ></textarea>
            </div>
            <SaveButton value={t("edit-button.modal.cta")} />
          </PerformanceForm>
        </Modal>
      </ReactModal>
    </div>
  );
};
export default Assets;
