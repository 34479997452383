import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import DownloadIcon from "../../images/icons/download.svg";
import {
  createJpgFromDom,
  createPdfFromDom,
  createPngFromDom,
} from "../utilities/utilities";

const Download = styled.div`
  position: relative;
  z-index: 10;
  button.download-button-container {
    background: ${(props) => props.theme.variables.buttons.download.background};
    height: 20px;
    width: 20px;
    border-radius: 1px;
    transition: all 250ms;
    border: none;
    &:hover {
      background: ${(props) =>
        props.theme.variables.buttons.download.hoverBackground};
    }
    .icon {
      path {
        fill: ${(props) => props.theme.variables.buttons.download.color};
        stroke: ${(props) => props.theme.variables.buttons.download.color};
      }
    }
  }
  .download-menu {
    position: relative;
    display: inline-block;
    z-index: 8;
    ul {
      padding: 0;
    }
    li {
      margin: 0;
    }
  }
  #download-menu-hide,
  #download-menu-show {
    display: none;
    position: absolute;
    margin: 0.5rem 0 0 0.75rem;
    background: ${(props) => props.theme.variables.menus.background};
    border: 1px solid ${(props) => props.theme.variables.menus.border};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.75);
    z-index: 1;
  }
  #download-menu-show {
    display: block;
  }
  .left-oriented-menu {
    inset: auto 0 auto auto;
  }
  #asset-link {
    background: transparent;
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
    font-size: 0.75rem;
    color: ${(props) => props.theme.variables.color};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    &:hover {
      background: ${(props) =>
        props.theme.variables.inputs.select.hoverBackground};
    }
    .show {
      display: block;
      visibility: visible;
    }
  }
  .right-oriented-button {
    min-width: 100px;
  }
  .left-oriented-button {
    min-width: 130px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    .download-menu-show {
      inset: auto 0 auto auto;
    }
  }
`;

const DownloadButton = (props) => {
  // Display download options in menu dropdown
  const [downloadMenuClass, setDownloadMenuClass] =
    useState("download-menu-hide");
  const toggleDownloadOptions = () => {
    if (downloadMenuClass === "download-menu-hide") {
      setDownloadMenuClass("download-menu-show");
    } else {
      setDownloadMenuClass("download-menu-hide");
    }
  };
  // For enabling print page function (server side rendering safe guard)
  const isBrowser = typeof window !== "undefined";

  // Translation component
  const { t } = useTranslation();

  return (
    <Download>
      <div className="download-menu">
        <button
          className="download-button-container"
          onClick={toggleDownloadOptions}
        >
          <DownloadIcon className="icon" alt="Download asset icon" />
        </button>
        <div
          id={downloadMenuClass}
          className={
            props.downloadOptions.menu_display_direction === "left"
              ? "left-oriented-menu"
              : null
          }
        >
          <ul>
            <li>
              {isBrowser ? (
                <button
                  id="asset-link"
                  onClick={() => {
                    toggleDownloadOptions();
                    createPdfFromDom(
                      window.document.getElementsByName(props.htmlClass.id)[0],
                      props.projectName,
                      props.htmlClass,
                      props.htmlClass.id,
                      props.type
                    );
                  }}
                >
                  {t("download.print")}
                </button>
              ) : null}
            </li>
            {props.downloadOptions.options.map((optionItem, index) => {
              return (
                <li key={`download-options-${index}`}>
                  <button
                    id="asset-link"
                    className={
                      props.downloadOptions.menu_display_direction === "left"
                        ? "left-oriented-button"
                        : "right-oriented-button"
                    }
                    onClick={() => {
                      toggleDownloadOptions();
                      optionItem.title.includes("JPG")
                        ? createJpgFromDom(
                            window.document.getElementsByName(
                              props.htmlClass.id
                            )[0],
                            props.projectName,
                            props.type
                          )
                        : createPngFromDom(
                            window.document.getElementsByName(
                              props.htmlClass.id
                            )[0],
                            props.projectName,
                            props.type
                          );
                    }}
                  >
                    {optionItem.title}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Download>
  );
};

export default DownloadButton;
