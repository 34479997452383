import Select from "react-select";
import React from "react";

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "black" }),
  option: (styles, state) => ({
    ...styles,
    color: "white", // Change text color on hover
    backgroundColor: "black", // Change background color on hover
    "&:hover": {
      backgroundColor: "lightgray", // Change background color on hover
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "black", // Change background color of the options container
    border: "1px solid #505050",
  }),
};

const SelectBy = ({ optionsHandler, changeHandler, text }) => {
  return (
    <div style={{ width: "30%", float: "left", padding: "10px" }}>
      <div
        style={{
          paddingBottom: "10px",
          fontWeight: "bold",
        }}
      >
        {text}
      </div>
      <Select
        isMulti
        styles={colourStyles}
        options={optionsHandler}
        onChange={changeHandler}
      />
      <br />
    </div>
  );
};

export default SelectBy;
