import React from "react";
import styled from "styled-components";
import { userRoles } from "../utilities/utilities"
import { useTranslation } from "gatsby-plugin-react-i18next";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Scatter,
} from "recharts";

const Card = styled.article`
  padding: 1rem;
  background: ${(props) => props.theme.variables.charts.background};
  border: 1px solid ${(props) => props.theme.variables.cards.benchmarks.border};
  border-radius: 3px;
  transition: all 250ms;
  &:hover {
    border: 1px solid
      ${(props) => props.theme.variables.cards.project.hoverBorder};
  }
`;

const Card2 = styled.article`
  padding: 1rem;
  background: ${(props) => props.theme.variables.charts.background};
  border: 1px solid ${(props) => props.theme.variables.cards.benchmarks.border};
  border-radius: 3px;
  transition: all 250ms;
  width: 545px;
  &:hover {
    border: 1px solid
      ${(props) => props.theme.variables.cards.project.hoverBorder};
  }
`;

const Diagram = styled.div`
  ul {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  li {
    font-size: 0.75rem;
  }
  .color-block {
    height: 10px;
    width: 10px;
    margin: 0 7px 0 0;
    display: inline-block;
  }
  .blue {
    background: ${(props) => props.theme.colors.chartColors.recover.lightBlue};
  }
  .pale-green {
    background: ${(props) => props.theme.colors.chartColors.recover.paleGreen};
    border-radius: 100%;
  }
`;

const Diagram2 = styled.div`
  ul {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  li {
    font-size: 0.75rem;
  }
  .color-block {
    height: 10px;
    width: 10px;
    margin: 0 7px 0 0;
    display: inline-block;
  }
  .blue {
    background: ${(props) => props.theme.colors.chartColors.recover.lightBlue};
  }
  .pale-green {
    background: ${(props) => props.theme.colors.chartColors.recover.paleGreen};
    border-radius: 100%;
  }
`;

const Results = (props) => {
  // Translation component
  const { t } = useTranslation();
  const { leaksPerPoiPerLicense } = props;

  // Set tooltip for bar charts
  const customTooltip = ({ payload, label, active }) => {
    if (active && payload?.length) {
      return (
        <div className="bar-chart-tooltips">
          <p>
            <span className="bar-chart-date">{label}</span>
            <br />
            {t("leaks-poi-per-license.leaks")}:{" "}
            <span className="mid-weight">
              {payload[0].payload.leaks_per_poi}%
            </span>
            <br />
            {t("leaks-poi-per-license.percent-investigated")}:{" "}
            <span className="mid-weight">
              {`${payload[0].payload.percent_investigate}%`}
            </span>
          </p>
        </div>
      );
    }
    return null;
  };
  // Title case the bar chart legend
  const renderLegend = ({ payload }) => {
    return (
      <ul>
        {payload.map((entry, index) => {
          if (entry.value.toLowerCase() === "leaks_per_poi") {
            return (
              <li key={`legend-${index}`}>
                <span className="color-block blue" />
                {t("leaks-poi-per-license.leaks")}
              </li>
            );
          } else if (entry.value.toLowerCase() === "percent_investigate") {
            return (
              <li key={`legend-${index}`}>
                <span className="color-block pale-green" />
                {t("leaks-poi-per-license.percent-investigated")}
              </li>
            );
          } else {
            console.log("There is an error in the data property.");
            return <li>Error!</li>;
          }
        })}
      </ul>
    );
  };

  const leakPerLicenseContainer = (
    <ResponsiveContainer width="90%" height={315}>
      <ComposedChart
        data={leaksPerPoiPerLicense}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 20,
        }}
      >
        <CartesianGrid stroke="#4A4A4A" vertical={false} />
        <XAxis dataKey="id" style={{ fill: "#FFF", overflow: "visible" }} />
        <YAxis
          style={{ fill: "#FFF" }}
          tickFormatter={(tick) => {
            return `${tick}%`;
          }}
        />
        <Tooltip content={customTooltip} cursor={{ fill: "none" }} />
        <Legend content={renderLegend} />
        <Bar dataKey="leaks_per_poi" fill="#3355FF" />
        <Scatter dataKey="percent_investigate" fill="#00DBA7" />
      </ComposedChart>
    </ResponsiveContainer>
  );

  return (
    <>
      {props.role != userRoles.CLIENT_VIEWER && props.pdf === false && (
        <Card>
          <Diagram>{leakPerLicenseContainer}</Diagram>
        </Card>
      )}
      {props.role != userRoles.CLIENT_VIEWER && props.pdf && (
        <Card2>
          <Diagram2>{leakPerLicenseContainer}</Diagram2>
        </Card2>
      )}
    </>
  );
};
export default Results;
