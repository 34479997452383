import React, { useState, useEffect } from "react";
import {
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/ducks/general";

const styles = {
  mainDiv: {
    width: "100%",
    height: "100%",
    zIndex: 10,
    position: "fixed",
    bottom: "0",
    right: "0",
    display: "block",
  },
  blackDivBackground: {
    backgroundColor: "black",
    width: "100%",
    height: "100%",
    opacity: "0.7",
    zIndex: 99,
    position: "fixed",
  },
  imageCon: {
    width: "100%",
    height: "100%",
    zIndex: 100,
    position: "fixed",
    paddingTop: "6%",
  },
  imageArrowCon: {
    width: "100%",
    height: "70%",
    float: "left",
  },
  closeCon: {
    color: "white",
    float: "right",
    marginRight: "49.2%",
    marginBottom: "2%",
    cursor: "pointer",
  },
  rightArrowCon: {
    width: "10%",
    height: "100%",
    float: "right",
  },
  rightArrow: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "100%",
    cursor: "pointer",
  },
  leftArrowCon: {
    width: "10%",
    height: "100%",
    float: "left",
  },
  leftArrow: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "100%",
    cursor: "pointer",
  },
  img: {
    width: "80%",
    height: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    opacity: "2.0",
  },
  loading: {
    fontSize: "70px",
    textAlign: "center",
  },
  photo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
};

const PhotoModal = (props) => {
  const [imageSrc, setImageSrc] = useState("");
  const [imageNumber, setImageNumber] = useState();

  const dispatch = useDispatch();

  const handleImageContainer = () => {
    props.setShowImage(false);
    setImageNumber(0);
    setImageSrc("");
  };

  useEffect(() => {
    if (
      props.imageArraySrc &&
      props.imageArraySrc.length > 0 &&
      imageSrc == ""
    ) {
      setImageSrc(props.imageArraySrc[0]);
      setImageNumber(0);
      dispatch(setLoading(false));
    }
  }, [props.imageArraySrc]); // Only re-run the effect if props.imageArraySrc changes

  const handleNextImage = () => {
    if (imageNumber + 1 < props.imageArraySrc.length) {
      setImageSrc(props.imageArraySrc[imageNumber + 1]);
      setImageNumber(imageNumber + 1);
    } else {
      setImageSrc(props.imageArraySrc[0]);
      setImageNumber(0);
    }
  };
  const handlePerviousImage = () => {
    if (imageNumber - 1 >= 0) {
      setImageSrc(props.imageArraySrc[imageNumber - 1]);
      setImageNumber(imageNumber - 1);
    } else {
      setImageSrc(props.imageArraySrc[props.imageArraySrc.length - 1]);
      setImageNumber(props.imageArraySrc.length - 1);
    }
  };
  return (
    <div style={styles.mainDiv}>
      <div style={styles.blackDivBackground}></div>
      <div style={styles.imageCon}>
        <div
          style={{
            width: "100%",
            float: "left",
          }}
        >
          <AiOutlineCloseCircle
            size={40}
            onClick={handleImageContainer}
            style={styles.closeCon}
          />
        </div>

        <div style={styles.imageArrowCon}>
          <div style={styles.rightArrowCon}>
            {props.imageArraySrc && props.imageArraySrc.length > 1 && (
              <AiFillCaretRight
                onClick={handlePerviousImage}
                size={70}
                style={styles.rightArrow}
              />
            )}
          </div>
          <div style={styles.leftArrowCon}>
            {props.imageArraySrc && props.imageArraySrc.length > 1 && (
              <AiFillCaretLeft
                onClick={handleNextImage}
                size={70}
                style={styles.leftArrow}
              />
            )}
          </div>
          <div style={styles.img}>
            <img
              src={`data:image/png;base64,${imageSrc}`}
              width="auto"
              height="100%"
              style={styles.photo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoModal;
