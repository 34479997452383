import React from "react";
import ImageLeak from "./image-leak";
const PopUpInfo = (props) => {
  const tableRow = props.tableRow;
  return (
    <>
      <b>
        Utilis Id : {tableRow.utilis_finding}
        <br></br>
        Investigation Result : {tableRow.verification_result}
        <br></br>
        Leak Type : {tableRow.leak_type}
        <br></br>
        Date : {tableRow.timestamp_corrected}
        <br></br>
        Location :{` ${tableRow.actual_y}, ${tableRow.actual_x}`}
        <br></br>
        Address : {tableRow.address}
        <br></br>
        {tableRow.s3_photo_temp == null ? (
          ""
        ) : (
          <>
            <div style={{ float: "left", marginTop: "5px" }}>Image :</div>
            <ImageLeak
              imageName={tableRow.s3_photo_temp}
              setShowImage={props.setShowImage}
              setImageArraySrc={props.setImageArraySrc}
            />
          </>
        )}
      </b>
    </>
  );
};

export default PopUpInfo;
