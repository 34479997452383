import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ReactModal from "react-modal";
import ImageLeak from "../layouts/image-leak";
import Pagination from "../layouts/pagination";
import Fuse from "fuse.js";

import CloseIcon from "../../images/icons/close.svg";
import SortIcon from "../../images/icons/sort.svg";
import ReverseSortIcon from "../../images/icons/simple_triangle.svg";

const Container = styled.section`
  .field-results-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0 1rem 0;
  }
  table.field-results {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border-radius: 3px;
    overflow: hidden;
    font-size: 0.75rem;
  }
  th {
    font-weight: 300;
    text-align: left;
    font-size: 0.75rem;
    padding: 0.25rem 1rem;
    border-bottom: 1px solid ${(props) => props.theme.variables.charts.border};
    &:not(:last-child) {
      border-right: 1px solid ${(props) => props.theme.variables.charts.border};
    }
  }
  th.comments {
    width: 20%;
  }
  td.photos {
    text-align: center;
    vertical-align: middle;
  }
  td {
    text-align: left;
    font-size: 0.75rem;
    padding: 0.25rem 1rem;
    border-bottom: 1px solid ${(props) => props.theme.variables.charts.border};
    &:not(:last-child) {
      border-right: 1px solid ${(props) => props.theme.variables.charts.border};
    }
  }
  p {
    font-size: 0.75rem;
    line-height: 1.3;
  }
  button.read-more {
    padding: 0.3rem 0 0 0;
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.primaryColors.lightBlue};
    text-transform: uppercase;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xxl}) {
    .field-results-title-row {
      padding: 1rem 0;
    }
  }
  /* Custom break points here so that table is still viewable on smaller sizes */
  @media (max-width: 1425px) {
    table.field-results,
    td,
    th,
    span,
    p,
    button.read-more {
      font-size: 0.6rem;
    }
  }
  @media (max-width: 1350px) {
    th,
    td {
      padding: 0.25rem 0.5rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xl}) {
    th,
    td {
      padding: 0.25rem 0 0 0.25rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    display: none;
  }
`;

const Chart = styled.form`
  padding: 0 0 4rem 0;
  .search-row {
    margin: 0 0 2rem 0;
  }
  .category-row {
    background: ${(props) => props.theme.variables.charts.categoryBackground};
  }
  .view-show {
    display: block;
  }
  .view-hide {
    display: none;
  }
  #no-results-row {
    padding: 4rem 0;
    text-align: center;
    width: 100%;
  }
  .quick-navigation {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
  }
  .show-more-button,
  .back-to-top-button {
    font-size: 0.8125rem;
    font-weight: 500;
    position: absolute;
    border: none;
    background: none;
    /* inset:1rem 0 -2rem auto; */
    letter-spacing: 1px;
    color: ${(props) => props.theme.variables.color};
    text-transform: uppercase;
    &:hover {
      color: ${(props) => props.theme.colors.secondaryColors.orange};
    }
  }
  .show-more-button {
    inset: 1rem 0 -2rem auto;
  }
  .back-to-top-button {
    inset: 1rem auto -2rem 0;
  }
`;

const Modal = styled.div`
  color: ${(props) => props.theme.variables.color};
  z-index: 1;
  width: 400px;
  button.close-modal-button {
    position: absolute;
    inset: 1rem 1rem auto auto;
    background: none;
    border: none;
    line {
      transition: all 250ms;
    }
    &:hover {
      line {
        stroke: ${(props) => props.theme.colors.secondaryColors.orange};
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    width: 350px;
    padding: 2rem;
  }
`;

const SortButton = styled.button`
  border: none;
  background: none;
  color: ${(props) => props.theme.variables.color};
  display: flex;
  align-items: center;
  font-weight: 300;
  text-align: left;
  font-size: 0.75rem;
  path {
    transition: all 250ms;
  }
  .sort-icon {
    margin: 0 0 0 0.35rem;
    opacity: 0;
    transition: all 500ms;
    min-width: 10px;
  }
  .activate-sort {
    opacity: 1;
  }
  .reverse-sort {
    opacity: 1;
  }
  &:hover {
    color: ${(props) => props.theme.colors.secondaryColors.orange};
    path {
      fill: ${(props) => props.theme.colors.secondaryColors.orange};
    }
  }
  @media (max-width: 1425px) {
    font-size: 0.6rem;
  }
`;

const Search = styled.input`
  width: 25%;
  background: ${(props) => props.theme.variables.inputs.search.background};
  border-radius: 25px;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  color: ${(props) => props.theme.variables.color};
  border: 1px solid ${(props) => props.theme.variables.inputs.search.border};
  &::placeholder {
    color: #fff;
    text-transform: uppercase;
  }
  &:focus {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.colors.secondaryColors.green};
    color: ${(props) => props.theme.colors.secondaryColors.green};
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xl}) {
    font-size: 0.65rem;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    padding: 0.75rem 1rem;
    font-size: 0.75rem;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    font-size: 0.9375rem;
  }
`;

const FieldResults = (props) => {
  const {
    circlesResult,
    fieldData,
    filteredData,
    map,
    setImageArraySrc,
    setScrollToMap,
    setShowImage,
  } = props;
  const { t } = useTranslation();

  const leakTypeKey = {
    Main: t("leak-type.main"),
    Service: t("leak-type.total.service"),
    "Customer-Side": t("leak-type.total.customer-side"),
    WO: t("leak-type.total.wo"),
    Other: t("leak-type.total.other"),
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex =
    currentPage === 1
      ? lastPostIndex - postPerPage
      : lastPostIndex - postPerPage + 1;

  // Fuzzy search functionality. Configure settings available here https://fusejs.io/
  const [queryTerm, setQueryTerm] = useState("");
  const [fieldTable, setFieldTable] = useState();
  const [showComments, setShowComments] = useState([]);
  const [showAddress, setShowAddress] = useState([]);
  const [sortKey, setSortKey] = useState("");

  // add unique index value foreach row in field result table
  fieldData.field_results = fieldData.field_results?.map((element, index) => {
    return { ...element, index: index };
  });

  useEffect(() => {
    setFieldTable(filteredData.mdcFeildResTest.field_results);
    sortFieldResultOnChange(sortKey);
    setCurrentPage(1);
  }, [filteredData]);

  useEffect(() => {
    const tempShowCommentsOrAddress = Array(
      filteredData.mdcFeildResTest.field_results?.length
    ).fill(false);

    setShowComments(tempShowCommentsOrAddress);
    setShowAddress(tempShowCommentsOrAddress);
  }, []);

  useEffect(() => {
    if (queryTerm == "") {
      setFieldTable(filteredData.mdcFeildResTest.field_results);
      sortFieldResultOnChange(sortKey);
      setCurrentPage(1);
    } else {
      const fieldResultsFuse = new Fuse(
        filteredData.mdcFeildResTest.field_results,
        {
          keys: [
            "utilis_finding",
            "verification_result",
            "leak_type",
            "visible",
            "address",
            "timestamp_corrected",
            "comments",
            "reference ",
          ],
          threshold: 0.1,
          ignoreLocation: true,
        }
      );
      const results = fieldResultsFuse
        ?.search(queryTerm)
        ?.map((item) => item.item);

      for (const obj of results) {
        for (const k in obj) {
          const v = obj[k];
          if (v === null) {
            obj[k] = "";
          }
        }
      }

      setFieldTable(results);
      setCurrentPage(1);
    }
  }, [queryTerm]);

  // Toggle no results message
  const [noResults, setNoResults] = useState("view-hide");
  const inputSearchTerm = (e) => {
    setQueryTerm(e.target.value);
  };

  // Toggling of modal window
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const toggleModal = (e, activeImage) => {
    e.preventDefault();
    setCurrentImage(activeImage);
    setModalIsOpen(!modalIsOpen);
  };

  const sortArray = (arr, key) => {
    arr?.sort((a, b) => {
      return a[key] === b[key] ? 0 : a[key] < b[key] ? 1 : -1;
    });

    return arr;
  };

  const showOrHideFullAddress = (e, id) => {
    e.preventDefault();
    const tempArrShowAddress = [...showAddress];
    tempArrShowAddress[id]
      ? (tempArrShowAddress[id] = false)
      : (tempArrShowAddress[id] = true);
    setShowAddress(tempArrShowAddress);
  };
  const showOrHideFullComments = (e, id) => {
    e.preventDefault();
    const tempArrShowComments = [...showComments];
    tempArrShowComments[id]
      ? (tempArrShowComments[id] = false)
      : (tempArrShowComments[id] = true);
    setShowComments(tempArrShowComments);
  };

  // Replace with your preferred function to query database and to sort Field Results rows
  const [activeSort, setActiveSort] = useState({
    id: "sort-icon",
    investigationResult: "sort-icon",
    leakType: "sort-icon",
    visible: "sort-icon",
    address: "sort-icon",
    date: "sort-icon",
    reference: "sort-icon",
  });

  const sortKeyMap = {
    id: "utilis_finding",
    investigationResult: "verification_result",
    leakType: "leak_type",
    visible: "visible",
    address: "address",
    date: "timestamp_corrected",
    reference: "reference",
  };

  const setSortIcon = (key) => {
    const styleSort = {
      id: "sort-icon",
      investigationResult: "sort-icon",
      leakType: "sort-icon",
      visible: "sort-icon",
      address: "sort-icon",
      date: "sort-icon",
      reference: "sort-icon",
    };
    if (activeSort[key] === "sort-icon activate-sort") {
      styleSort[key] = "sort-icon reverse-sort";
    } else {
      styleSort[key] = "sort-icon activate-sort";
    }
    setActiveSort(styleSort);
  };

  const sortFieldResultOnChange = (key) => {
    if (filteredData.mdcFeildResTest.field_results) {
      if (key) {
        setFieldTable(
          sortArray(filteredData.mdcFeildResTest.field_results, sortKeyMap[key])
        );
      } else {
        setFieldTable(
          sortArray(
            filteredData.mdcFeildResTest.field_results,
            "utilis_finding"
          )
        );
      }
    }
  };

  const sortFieldResults = (e, key) => {
    e.preventDefault();
    const tempFieldTable = [...fieldTable];
    if (sortKey !== key) {
      setSortKey(key);
      setFieldTable(sortArray(fieldTable, sortKeyMap[key]));
    } else {
      setFieldTable(tempFieldTable?.reverse());
    }
    setSortIcon(key);
  };

  // Replace with your function to query your database
  const showMore = (e) => {
    e.preventDefault();
  };

  return (
    <Container>
      <div className="field-results-title-row" id="field-results-top">
        <h5>{t("field-results.h5")}</h5>
        <Search
          type="text"
          id="search-field-desktop"
          placeholder={t("search.placeholder")}
          onChange={inputSearchTerm}
        />
      </div>
      <Chart>
        <table className="field-results">
          <tbody>
            <tr className="category-row">
              <th>
                <SortButton onClick={(e) => sortFieldResults(e, "id")}>
                  {t("field-results.id")}{" "}
                  {activeSort.id === "sort-icon" && <span>&nbsp;&nbsp;</span>}
                  {activeSort.id === "sort-icon activate-sort" && (
                    <SortIcon alt="Sort icon" className={activeSort.id} />
                  )}
                  {activeSort.id === "sort-icon reverse-sort" && (
                    <ReverseSortIcon
                      alt="Reverse Sort Icon"
                      className={activeSort.id}
                    />
                  )}
                </SortButton>
              </th>
              <th>
                <SortButton
                  onClick={(e) => sortFieldResults(e, "investigationResult")}
                >
                  {t("field-results.investigation-results")}{" "}
                  {activeSort.investigationResult === "sort-icon" && (
                    <span>&nbsp;&nbsp;</span>
                  )}
                  {activeSort.investigationResult ===
                    "sort-icon activate-sort" && (
                    <SortIcon
                      alt="Sort icon"
                      className={activeSort.investigationResult}
                    />
                  )}
                  {activeSort.investigationResult ===
                    "sort-icon reverse-sort" && (
                    <ReverseSortIcon
                      alt="Reverse Sort Icon"
                      className={activeSort.investigationResult}
                    />
                  )}
                </SortButton>
              </th>
              <th>
                <SortButton onClick={(e) => sortFieldResults(e, "leakType")}>
                  {t("field-results.leak-type")}{" "}
                  {activeSort.leakType === "sort-icon" && (
                    <span>&nbsp;&nbsp;</span>
                  )}
                  {activeSort.leakType === "sort-icon activate-sort" && (
                    <SortIcon alt="Sort icon" className={activeSort.leakType} />
                  )}
                  {activeSort.leakType === "sort-icon reverse-sort" && (
                    <ReverseSortIcon
                      alt="Reverse Sort Icon"
                      className={activeSort.leakType}
                    />
                  )}
                </SortButton>
              </th>
              <th>
                <SortButton onClick={(e) => sortFieldResults(e, "visible")}>
                  {t("field-results.visible")}
                  {activeSort.visible === "sort-icon" && (
                    <span>&nbsp;&nbsp;</span>
                  )}
                  {activeSort.visible === "sort-icon activate-sort" && (
                    <SortIcon alt="Sort icon" className={activeSort.visible} />
                  )}
                  {activeSort.visible === "sort-icon reverse-sort" && (
                    <ReverseSortIcon
                      alt="Reverse Sort Icon"
                      className={activeSort.visible}
                    />
                  )}
                </SortButton>
              </th>
              <th>
                <SortButton onClick={(e) => sortFieldResults(e, "address")}>
                  {t("field-results.address")}
                  {activeSort.address === "sort-icon" && (
                    <span>&nbsp;&nbsp;</span>
                  )}
                  {activeSort.address === "sort-icon activate-sort" && (
                    <SortIcon alt="Sort icon" className={activeSort.address} />
                  )}
                  {activeSort.address === "sort-icon reverse-sort" && (
                    <ReverseSortIcon
                      alt="Reverse Sort Icon"
                      className={activeSort.address}
                    />
                  )}
                </SortButton>
              </th>
              <th>
                <SortButton onClick={(e) => sortFieldResults(e, "date")}>
                  {t("field-results.date")}
                  {activeSort.date === "sort-icon" && <span>&nbsp;&nbsp;</span>}
                  {activeSort.date === "sort-icon activate-sort" && (
                    <SortIcon alt="Sort icon" className={activeSort.date} />
                  )}
                  {activeSort.date === "sort-icon reverse-sort" && (
                    <ReverseSortIcon
                      alt="Reverse Sort Icon"
                      className={activeSort.date}
                    />
                  )}
                </SortButton>
              </th>
              <th className="comments">{t("field-results.comments")}</th>
              <th>
                <SortButton onClick={(e) => sortFieldResults(e, "reference")}>
                  {t("field-results.reference")}
                  {activeSort.reference === "sort-icon" && (
                    <span>&nbsp;&nbsp;</span>
                  )}
                  {activeSort.reference === "sort-icon activate-sort" && (
                    <SortIcon
                      alt="Sort icon"
                      className={activeSort.reference}
                    />
                  )}
                  {activeSort.reference === "sort-icon reverse-sort" && (
                    <ReverseSortIcon
                      alt="Reverse Sort Icon"
                      className={activeSort.reference}
                    />
                  )}
                </SortButton>
              </th>
              <th>{t("field-results.photos")}</th>
            </tr>
            {fieldTable &&
              fieldTable.map((fieldItem, index) => {
                if (firstPostIndex <= index && lastPostIndex >= index) {
                  return (
                    <tr key={index}>
                      <td>{fieldItem.utilis_finding}</td>
                      <td>{t(fieldItem.verification_result?.toLowerCase())}</td>
                      <td>{leakTypeKey[fieldItem.leak_type]}</td>
                      <td>{t(fieldItem.visible?.toLowerCase())}</td>
                      <td style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            setScrollToMap(true);
                            circlesResult[fieldItem.index]?.openOn(map);
                            map?.setView(
                              [fieldItem.actual_y, fieldItem.actual_x],
                              18
                            );
                          }}
                        >
                          {showAddress[index] == true
                            ? fieldItem.address
                            : fieldItem.address?.substring(0, 20)}
                        </a>
                        <p></p>
                        {showAddress[index] == true ? (
                          <>
                            {fieldItem.address?.length > 20 && (
                              <button
                                className="read-more"
                                onClick={(e) => showOrHideFullAddress(e, index)}
                              >
                                {t("see-less")}
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {fieldItem.address?.length > 20 && (
                              <button
                                className="read-more"
                                onClick={(e) => showOrHideFullAddress(e, index)}
                              >
                                {t("read-more")}
                              </button>
                            )}
                          </>
                        )}
                      </td>
                      <td>{fieldItem.timestamp_corrected}</td>
                      <td>
                        {showComments[index] == true ? (
                          <>
                            <p>{fieldItem.comments}</p>
                            {fieldItem.comments?.length > 20 && (
                              <button
                                className="read-more"
                                onClick={(e) =>
                                  showOrHideFullComments(e, index)
                                }
                              >
                                {t("see-less")}
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            <p>
                              {fieldItem.comments &&
                                fieldItem.comments?.substring(0, 20)}
                            </p>
                            {fieldItem.comments?.length > 20 && (
                              <button
                                className="read-more"
                                onClick={(e) =>
                                  showOrHideFullComments(e, index)
                                }
                              >
                                {t("read-more")}
                              </button>
                            )}
                          </>
                        )}
                      </td>
                      <td>{fieldItem["reference "]}</td>
                      <td>
                        {fieldItem.s3_photo_temp && (
                          <ImageLeak
                            imageName={fieldItem.s3_photo_temp}
                            setShowImage={setShowImage}
                            setImageArraySrc={setImageArraySrc}
                          />
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
          </tbody>
        </table>

        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postPerPage={postPerPage}
          fieldResultsWoFiltered={fieldTable}
        ></Pagination>

        <div id="no-results-row" className={noResults}>
          <p>{t("no-results")}</p>
        </div>
        <div className="quick-navigation"></div>
      </Chart>
      {/* Modal */}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        className={"modal-content"}
        overlayClassName={"modal-overlay"}
        ariaHideApp={false}
      >
        <Modal>
          <img
            src={currentImage}
            alt="Investigation location"
            className="img-fluid"
          />
          <button onClick={toggleModal} className="close-modal-button">
            <CloseIcon aria-label="button" alt="Close icon" />
          </button>
        </Modal>
      </ReactModal>
    </Container>
  );
};
export default FieldResults;
