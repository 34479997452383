import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import DownloadButton from "../buttons/download-button";
import { userRoles } from "../utilities/utilities"

const Card = styled.article`
  button#sectionHeader {
    color: ${(props) => props.theme.colors.primaryColors.white};
    padding: 0.75rem 0;
    font-size: 0.75rem;
    text-align: center;
    cursor: pointer;
    &:not(:last-child) {
      border-right: 1px solid
        ${(props) => props.theme.variables.cards.benchmarks.border};
    }
    &:hover {
      background: ${(props) => props.theme.variables.charts.hoverTabBackground};
    }
  }
  .tabs {
    transition: all 250ms;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border: 1px solid
      ${(props) => props.theme.variables.cards.benchmarks.border};
    background: ${(props) => props.theme.variables.charts.tabBackground};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .active {
    background: ${(props) => props.theme.colors.primaryColors.blue};
    font-weight: 600;
    &:hover {
      background: ${(props) =>
        props.theme.colors.primaryColors.blue} !important;
    }
  }
  .content-container {
    padding: 1rem;
    background: ${(props) => props.theme.variables.charts.background};
    border-left: 1px solid
      ${(props) => props.theme.variables.cards.benchmarks.border};
    border-right: 1px solid
      ${(props) => props.theme.variables.cards.benchmarks.border};
    border-bottom: 1px solid
      ${(props) => props.theme.variables.cards.benchmarks.border};
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    transition: all 250ms;
  }
  #days,
  #weeks,
  #months {
  }
  &:hover {
    .tabs {
      border-left: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
      border-right: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
      border-top: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
    }
    .content-container {
      border-left: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
      border-right: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
      border-bottom: 1px solid
        ${(props) => props.theme.variables.cards.project.hoverBorder};
    }
  }
`;

const Diagram = styled.div`
  position: relative;
  ul.legend {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  li {
    font-size: 0.75rem;
  }
  .color-block {
    height: 10px;
    width: 10px;
    margin: 0 7px 0 0;
    display: inline-block;
  }
  .blue {
    background: ${(props) => props.theme.colors.chartColors.recover.lightBlue};
  }
  .pale-green {
    background: ${(props) => props.theme.colors.chartColors.recover.paleGreen};
  }
  .download-button {
    position: absolute;
    inset: 0 0 auto auto;
    z-index: 1;
  }
`;

const Results = (props) => {
  // Translation component
  const { t } = useTranslation();

  const { role, projectName, resultsByDate } = props;

  // The properties for dom element
  const htmlClass = {
    id: "resultsByDate",
    rotation: 0,
    lMargin: 0,
    tMargin: 15,
    width: 235,
    height: 350,
  };

  const graphName = `ResultsByDate`;

  const options = {
    options: [
      {
        id: 0,
        title: t("download.jpg"),
      },
      {
        id: 1,
        title: t("download.png"),
      },
    ],
    menu_display_direction: "left",
  };

  // Toggle display of active tab
  const [tabSections, setTabSections] = useState({
    days: "show-content",
    daysSection: "active",
    weeks: "hide-content",
    weeksSection: "",
    months: "hide-content",
    monthsSection: "",
  });

  const openTab = (tab) => {
    if (tab === "days") {
      setTabSections({
        days: "show-content",
        daysSection: "active",
        weeks: "hide-content",
        weeksSection: "",
        months: "hide-content",
        monthsSection: "",
      });
    } else if (tab === "weeks") {
      setTabSections({
        days: "hide-content",
        daysSection: "",
        weeks: "show-content",
        weeksSection: "active",
        months: "hide-content",
        monthsSection: "",
      });
    } else if (tab === "months") {
      setTabSections({
        days: "hide-content",
        daysSection: "",
        weeks: "hide-content",
        weeksSection: "",
        months: "show-content",
        monthsSection: "active",
      });
    }
  };

  // Set tooltip for bar charts
  const customTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      return (
        <div className="bar-chart-tooltips">
          <p>
            <span className="bar-chart-date">{label}</span>
            <br />
            {t("results-by-date.leaks")}:{" "}
            <span className="mid-weight">{payload[0].payload.leaks}</span>
            <br />
            {t("results-by-date.suspected")}:{" "}
            <span className="mid-weight">{payload[0].payload.suspected}</span>
          </p>
        </div>
      );
    }
    return null;
  };

  // Title case the bar chart legend
  const renderLegend = ({ payload }) => {
    return (
      <ul className="legend">
        {payload.map((entry, index) => {
          if (entry.value.toLowerCase() === "leaks") {
            return (
              <li key={`legend-${index}`}>
                <span className="color-block blue" />
                {t("results-by-date.leaks")}
              </li>
            );
          } else if (entry.value.toLowerCase() === "suspected") {
            return (
              <li key={`legend-${index}`}>
                <span className="color-block pale-green" />
                {t("results-by-date.suspected")}
              </li>
            );
          } else {
            console.log("There is an error in the data property.");
            return <li>Error!</li>;
          }
        })}
      </ul>
    );
  };

  return (
    <>
      {role != userRoles.CLIENT_VIEWER && (
        <Card name="resultsByDate">
          <div className="tabs">
            <button
              id="sectionHeader"
              onClick={() => openTab("days")}
              className={tabSections.daysSection}
            >
              {t("results-by-date.days")}
            </button>
            <button
              id="sectionHeader"
              onClick={() => openTab("weeks")}
              className={tabSections.weeksSection}
            >
              {t("results-by-date.weeks")}
            </button>
            <button
              id="sectionHeader"
              onClick={() => openTab("months")}
              className={tabSections.monthsSection}
            >
              {t("results-by-date.months")}
            </button>
          </div>

          <div className="content-container">
            <div id="days" className={tabSections.days}>
              <Diagram>
                <ResponsiveContainer width="90%" height={275}>
                  <BarChart
                    data={resultsByDate.resultsByDateDays}
                    margin={{
                      top: 10,
                      right: 10,
                      left: 10,
                      bottom: 10,
                    }}
                  >
                    <CartesianGrid stroke="#4A4A4A" vertical={false} />
                    <XAxis dataKey="date" style={{ fill: "#FFF" }} />
                    <YAxis
                      label={{
                        value: t("results-by-date.number-of-leaks"),
                        angle: -90,
                        position: "center",
                        fill: "#FFF",
                        fontSize: "0.75rem",
                      }}
                      style={{ fill: "#FFF" }}
                    />
                    <Tooltip
                      content={customTooltip}
                      cursor={{ fill: "none" }}
                    />
                    <Legend content={renderLegend} />
                    <Bar dataKey="leaks" stackId="a" fill="#3355FF" />
                    <Bar dataKey="suspected" stackId="a" fill="#00DBA7" />
                  </BarChart>
                </ResponsiveContainer>
                <div className="download-button">
                  <DownloadButton
                    downloadOptions={options}
                    htmlClass={htmlClass}
                    projectName={projectName}
                    type={`${graphName}_Days`}
                  />
                </div>
              </Diagram>
            </div>

            <div id="weeks" className={tabSections.weeks}>
              <Diagram>
                <ResponsiveContainer width="90%" height={275}>
                  <BarChart
                    data={resultsByDate.resultsByDateWeeks}
                    margin={{
                      top: 10,
                      right: 10,
                      left: 10,
                      bottom: 10,
                    }}
                  >
                    <CartesianGrid stroke="#4A4A4A" vertical={false} />
                    <XAxis dataKey="date" style={{ fill: "#FFF" }} />
                    <YAxis
                      label={{
                        value: t("results-by-date.number-of-leaks"),
                        angle: -90,
                        position: "center",
                        fill: "#FFF",
                        fontSize: "0.75rem",
                      }}
                      style={{ fill: "#FFF" }}
                    />
                    <Tooltip
                      content={customTooltip}
                      cursor={{ fill: "none" }}
                    />
                    <Legend content={renderLegend} />
                    <Bar dataKey="leaks" stackId="a" fill="#3355FF" />
                    <Bar dataKey="suspected" stackId="a" fill="#00DBA7" />
                  </BarChart>
                </ResponsiveContainer>
                <div className="download-button">
                  <DownloadButton
                    downloadOptions={options}
                    htmlClass={htmlClass}
                    projectName={projectName}
                    type={`${graphName}_Weeks`}
                  />
                </div>
              </Diagram>
            </div>

            <div id="months" className={tabSections.months}>
              <Diagram>
                <ResponsiveContainer width="90%" height={275}>
                  <BarChart
                    data={resultsByDate.resultsByDateMonths}
                    margin={{
                      top: 10,
                      right: 10,
                      left: 10,
                      bottom: 10,
                    }}
                  >
                    <CartesianGrid stroke="#4A4A4A" vertical={false} />
                    <XAxis dataKey="date" style={{ fill: "#FFF" }} />
                    <YAxis
                      label={{
                        value: t("results-by-date.number-of-leaks"),
                        angle: -90,
                        position: "center",
                        fill: "#FFF",
                        fontSize: "0.75rem",
                      }}
                      style={{ fill: "#FFF" }}
                    />
                    <Tooltip
                      content={customTooltip}
                      cursor={{ fill: "none" }}
                    />
                    <Legend content={renderLegend} />
                    <Bar dataKey="leaks" stackId="a" fill="#3355FF" />
                    <Bar dataKey="suspected" stackId="a" fill="#00DBA7" />
                  </BarChart>
                </ResponsiveContainer>
                <div className="download-button">
                  <DownloadButton
                    downloadOptions={options}
                    htmlClass={htmlClass}
                    projectName={projectName}
                    type={`${graphName}_Months`}
                  />
                </div>
              </Diagram>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
export default Results;
