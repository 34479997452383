import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { MdInsertPhoto } from "react-icons/md";
import { setSnackBarMsg, setLoading } from "../../store/ducks/general";

const ImageLeak = (props) => {
  const loginValues = useSelector((state) => state.general.loginValues);
  const dispatch = useDispatch();

  const imageShow = async (imageName) => {
    if (!imageName || imageName == "" || imageName == null) {
      dispatch(setSnackBarMsg("No Image"));
      return;
    }
    try {
      const arrImages = await axios.get("/utilis-finding-photos", {
        params: {
          subProject: loginValues.subProject,
          photosName: imageName,
        },
      });
      const imageArryBuffer = arrImages.data.map((img) => {
        return toBase64(img.data);
      });

      props.setImageArraySrc(imageArryBuffer);
    } catch (err) {
      dispatch(setSnackBarMsg("Error Photos!!!"));
      console.log(err);
    }
  };

  function toBase64(arr) {
    //arr = new Uint8Array(arr) if it's an ArrayBuffer
    return btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), "")
    );
  }

  return (
    <>
      <MdInsertPhoto
        onClick={(e) => {
          dispatch(setLoading(true));
          props.setImageArraySrc("");
          imageShow(props.imageName);
          props.setShowImage(true);
        }}
        style={{ marginBottom: "10px", cursor: "pointer" }}
        size={25}
      />
    </>
  );
};
export default ImageLeak;
